import callBackend from 'common/functions/callBackend';
import { formatPieceParams } from 'helpers';
import { useEffect, useState } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

const getQueryString = () => {
  const queryParams = new URLSearchParams()

  queryParams.set('filterIsNotNull[]', 'autoArchivedAt')
  queryParams.set('filterIsNull[]', 'autoArchivalApprovedAt')

  return queryParams.toString()
};

// TODO: Used only by quick admin, make usable elsewhere also
const usePieces = () => {
  const [error, setError] = useState(undefined);
  const [pieces, setPieces] = useState([]);
  const [response, setResponse] = useState(undefined);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    if (status === 'complete') {
      setStatus('idle');
    }
  }, [status]);

  const fetchPieces = async ({ authToken }) => {
    setStatus('loading');

    const queryString = getQueryString();

    const piecesRequest = await callBackend({
      method: 'GET',
      url: `${API_URL}/pieces?${queryString}`,
      authHeader: authToken ? { Authorization: 'JWT ' + authToken } : undefined,
    });

    if (!piecesRequest.response) {
      setError('Pieces request failed.');
      setStatus('complete');
      return;
    }

    const returnedPieces = piecesRequest.response.map((piece) => formatPieceParams(piece));

    setResponse({ pieces: returnedPieces, success: true });
    setPieces([...pieces, ...returnedPieces]);
    setError(undefined);
    setStatus('complete');
  };

  return {
    error,
    fetchPieces,
    pieces,
    response,
    setPieces,
    status,
  };
};

export default usePieces;
