import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'components/Toggle';
import styles from './styles.module.css';

const Setting = ({ title, comment, onClick, enabled }) => {
  return (
    <div className={styles.setting}>
      <div className={styles.setting_content}>
        <div>{title}</div>
        <Toggle onClick={onClick} enabled={enabled} />
      </div>

      {comment && <div className={styles.setting_comment}>{comment}</div>}
    </div>
  );
};

Setting.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  comment: PropTypes.string,
  enabled: PropTypes.bool,
};

Setting.defaultProps = {
  comment: '',
  enabled: false,
};

export default Setting;
