import { useState } from 'react';

import { prop } from 'env';

const useFacebookLogin = () => {
  const [initialized, setInitialized] = useState(false);
  const [accessToken, setAccessToken] = useState(undefined);

  const fbInitialize = async () => {
    return new Promise(resolve => {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: prop('FACEBOOK_APP_ID'),
          cookie: true,
          xfbml: true,
          version: prop('FACEBOOK_API_VERSION'),
        });
        window.FB.getLoginStatus(response => resolve(response));
      };

      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    });
  };

  const fbLogin = async () => {
    return new Promise(resolve => {
      window.FB.login(response => {
        resolve(response);
      });
    });
  };

  const login = async () => {
    let response;

    if (!initialized) {
      response = await fbInitialize();
      setInitialized(true);
    }

    if (response && response.status !== 'connected') {
      response = await fbLogin();
    }

    const { status, authResponse } = response || {};

    setAccessToken(status === 'connected' ? authResponse.accessToken : undefined);
  };

  return {
    login,
    accessToken,
  };
};

export default useFacebookLogin;
