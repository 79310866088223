import SparkMD5 from 'spark-md5';

const md5File = async file => {
  const { arrayBuffer } = await new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.addEventListener('loadend', () => resolve({ arrayBuffer: fileReader.result }));
    fileReader.readAsArrayBuffer(file);
  });
  return SparkMD5.ArrayBuffer.hash(arrayBuffer, false);
};

export default md5File;
