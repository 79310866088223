import React, { useContext } from 'react'

import { prop } from 'env'

const ConfigContext = React.createContext({})

const ConfigProvider = ({ children }) => {
  const googleAnalytics = {
    measurementId: prop('GOOGLE_ANALYTICS_4_MEASUREMENT_ID')
  }

  return <ConfigContext.Provider value={{ googleAnalytics }}>{children}</ConfigContext.Provider>
}

export { ConfigContext, ConfigProvider }

export const useConfig = () => {
  const context = useContext(ConfigContext)
  if (!context) {
    throw new Error('Trying to use useConfig without ConfigProvider')
  }
  return context
}
