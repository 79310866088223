const Renderer = (function() {
  let gl; //gl ref
  let canvas; //canvas ref

  //shader programs
  let program; //paricle program
  // let progam_image; //image program

  let maxParticles = 32000;

  //data
  let dynamicPositions;
  let dynamicColours;
  let staticPositions;
  let staticColours;

  //pointer
  let dynamicIndex = 0;
  let staticIndex = 0;
  //buffers
  let dynamicPositionBuffer;
  let dynamicColourBuffer;

  //textures
  let texture_bg;
  let texture_bg_info;

  //quad cpu resources
  let quad_positions; //Float32Array
  let quad_texcoords; //Float32Array
  let quad_indices; //Uint16Array

  //quad gpu resources
  let quad_buffer_position;
  let quad_buffer_texcoord;
  let quad_buffer_indices;

  //render to texture
  let rttFramebuffer;
  let rttTexture;

  let program_texture;
  let staticPositionBuffer;
  let staticColourBuffer;

  let width;
  let height;

  /*utils*/
  function loadShader(source, type) {
    let shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.log(gl.getShaderParameter(shader, gl.INFO_LOG));
      console.log('invalid shader : ' + this.gl.getShaderInfoLog(shader));
      console.log(source);
    }
    return shader;
  }
  function loadProgram(vertexShaderSource, fragmentShaderSource) {
    let vertexShader = loadShader(vertexShaderSource, gl.VERTEX_SHADER);
    let fragmentShader = loadShader(fragmentShaderSource, gl.FRAGMENT_SHADER);
    let program = gl.createProgram();
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      console.log(gl.getProgramParameter(program, gl.INFO_LOG));
    }
    return program;
  }
  /*end utils*/

  /*render functions*/
  function renderQuad(texture, flipY) {
    gl.bindTexture(gl.TEXTURE_2D, texture);

    // Tell WebGL to use our shader program pair
    gl.useProgram(program_texture);

    //flip the y coord
    gl.uniform1f(gl.getUniformLocation(program_texture, 'flipper'), !!flipY);

    // look up where the vertex data needs to go. - TODO preprocess
    let positionLocation = gl.getAttribLocation(program_texture, 'a_position');
    let texcoordLocation = gl.getAttribLocation(program_texture, 'a_texcoord');

    // Setup the attributes to pull data from our buffers
    gl.bindBuffer(gl.ARRAY_BUFFER, quad_buffer_position);
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);
    gl.bindBuffer(gl.ARRAY_BUFFER, quad_buffer_texcoord);
    gl.enableVertexAttribArray(texcoordLocation);
    gl.vertexAttribPointer(texcoordLocation, 2, gl.FLOAT, false, 0, 0);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, quad_buffer_indices);

    gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
    // draw the quad (2 triangles, 6 vertices)
    //gl.drawArrays(gl.TRIANGLES, 0, 6);
    //end render image
  }
  function renderStaticParticles() {
    gl.useProgram(program);

    // Bind vertex buffer object
    gl.bindBuffer(gl.ARRAY_BUFFER, staticPositionBuffer);
    // Pass the vertex data to the buffer
    gl.bufferData(gl.ARRAY_BUFFER, staticPositions, gl.DYNAMIC_DRAW);

    let positionLocation = gl.getAttribLocation(program, 'position');
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(
      positionLocation, // index
      2, // number of components per element
      gl.FLOAT, // type of data
      false, // normalized
      0, // stride
      0
    ); // offset

    //colours
    gl.bindBuffer(gl.ARRAY_BUFFER, staticColourBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, staticColours, gl.DYNAMIC_DRAW);
    let colourLocation = gl.getAttribLocation(program, 'colour');
    gl.enableVertexAttribArray(colourLocation);
    gl.vertexAttribPointer(
      colourLocation, // index
      4, // number of components per element
      gl.UNSIGNED_BYTE, // type of data
      true, // normalized
      0, // stride
      0
    ); // offset

    /*
		  gl.drawElements(
				gl.LINES,           // what to draw
				3,                  // number of vertices
				gl.UNSIGNED_SHORT,  // type of indices
				0);                 // offset
		*/
    //gl.drawArrays(gl.TRIANGLE_STRIP, 0, 3);

    if (staticIndex > 0) {
      gl.drawArrays(gl.POINTS, 0, staticIndex);
    }
  }
  /*end render functions*/

  /*context*/
  function handleContextLost(event) {
    event.preventDefault();
    console.log('Renderer handleContextLost');
    //cancelRequestAnimationFrame(requestId);
  }
  function handleContextRestored() {
    console.log('Renderer handleContextRestored');
    initGPUResources();
  }
  /*end context*/

  function initCPUResources() {
    //dynamic particle data (empty initally)
    dynamicPositions = new Float32Array(maxParticles * 2); //(2 = x, y)
    dynamicColours = new Uint32Array(maxParticles);

    staticPositions = new Float32Array(maxParticles * 2);
    staticColours = new Uint32Array(maxParticles);

    // Put a unit quad in the buffer
    quad_positions = new Float32Array([-1, 1, 1, 1, -1, -1, 1, -1]);
    quad_texcoords = new Float32Array([0, 0, 1, 0, 0, 1, 1, 1]);
    quad_indices = new Uint16Array([0, 1, 2, 3, 2, 1]);
  }
  function initGPUResources() {
    initBuffers();
    initShaders();
    initTextures();
    initTextureFramebuffer();
  }
  function initBuffers() {
    // Create an empty buffer object to store the vertex buffer
    dynamicPositionBuffer = gl.createBuffer();
    //colours
    dynamicColourBuffer = gl.createBuffer();

    //static buffers
    staticPositionBuffer = gl.createBuffer();
    staticColourBuffer = gl.createBuffer();

    // Create a buffer for positions
    quad_buffer_position = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, quad_buffer_position);
    gl.bufferData(gl.ARRAY_BUFFER, quad_positions, gl.STATIC_DRAW);

    // Create a buffer for texture coords
    quad_buffer_texcoord = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, quad_buffer_texcoord);
    gl.bufferData(gl.ARRAY_BUFFER, quad_texcoords, gl.STATIC_DRAW);

    //create an index buffer
    quad_buffer_indices = gl.createBuffer();
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, quad_buffer_indices);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, quad_indices, gl.STATIC_DRAW);
  }
  function initShaders() {
    //create programs
    program = loadProgram(
      document.getElementById('vertexShader_particle').text,
      document.getElementById('fragmentShader_particle').text
    );

    program_texture = loadProgram(
      document.getElementById('vertexShader_texture').text,
      document.getElementById('fragmentShader_texture').text
    );
  }
  function initTextures() {
    //create textures
    texture_bg = gl.createTexture(); //create

    gl.bindTexture(gl.TEXTURE_2D, texture_bg);
    //params -  let's assume all images are not a power of 2
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  }
  function initTextureFramebuffer() {
    /*render texture*/
    rttTexture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, rttTexture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    //gl.generateMipmap(gl.TEXTURE_2D);

    let level = 0;
    let internalFormat = gl.RGBA;
    let border = 0;
    let format = gl.RGBA;
    let type = gl.UNSIGNED_BYTE;
    let data = null;

    gl.texImage2D(
      gl.TEXTURE_2D,
      level,
      internalFormat,
      canvas.width,
      canvas.height,
      border,
      format,
      type,
      data
    );

    /*framebuffer*/
    rttFramebuffer = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, rttFramebuffer);

    // attach the texture as the first color attachment
    let attachmentPoint = gl.COLOR_ATTACHMENT0;
    gl.framebufferTexture2D(gl.FRAMEBUFFER, attachmentPoint, gl.TEXTURE_2D, rttTexture, level);
  }
  function resetArray(array, value) {
    var length = array.length;
    for (let i = 0; i < length; i++) array[i] = value;
  }
  let module = {};

  //public api methods
  module.initialize = function(canvasIn) {
    canvas = canvasIn;
    canvas.addEventListener('webglcontextlost', handleContextLost, false);
    canvas.addEventListener('webglcontextrestored', handleContextRestored, false);
    /*
			canvas.setRestoreTimeout(5000);  // recover in 5 seconds
			canvas.setRestoreTimeout(-1);  // disable auto recover
			canvas.setRestoreTimeout(0);  //default*/
    gl = canvas.getContext('webgl', { antialias: false });

    width = canvas.width;
    height = canvas.height;

    initCPUResources();
    initGPUResources();

    module.reset();
  };

  module.reset = function() {
    //reset arrays
    resetArray(dynamicPositions, 0);
    resetArray(dynamicColours, 0 | 0);
    resetArray(staticPositions, 0);
    resetArray(staticColours, 0 | 0);

    //reset pointers
    dynamicIndex = staticIndex = 0;

    width = canvas.width;
    height = canvas.height;

    //update render target, currently this will lose all data, need to re push data
    gl.bindTexture(gl.TEXTURE_2D, rttTexture);
    let level = 0;
    let internalFormat = gl.RGBA;
    let border = 0;
    let format = gl.RGBA;
    let type = gl.UNSIGNED_BYTE;
    let data = null;
    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, format, type, data);

    // Set the view port
    gl.viewport(0, 0, width, height);
  };

  module.renderImageToStaticTexture = function(can) {
    //TODO BLIT THIS WITHOUT ANY SIZE - maybe
    let temp_texture = gl.createTexture(); //create

    gl.bindTexture(gl.TEXTURE_2D, temp_texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR); //NEAREST

    //gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, canvas.width, canvas.height, border, format, type, canvas);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, can);

    // render to our targetTexture by binding the framebuffer
    gl.bindFramebuffer(gl.FRAMEBUFFER, rttFramebuffer);

    // Tell WebGL how to convert from clip space to pixels
    gl.viewport(0, 0, canvas.width, canvas.height); //TODO derive this maybe from vars

    renderQuad(temp_texture);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    // Tell WebGL how to convert from clip space to pixels
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    //gl.clearColor(0,0,0,1);
    //this.clear();
  };
  module.setTextureSize = function(textureWidth, textureHeight) {};
  // module.resize = function(width, height) {
  //   canvas.width = width;
  //   canvas.height = height;
  //
  //   //update render target, currently this will lose all data, need to re push data
  //   gl.bindTexture(gl.TEXTURE_2D, rttTexture);
  //   let level = 0;
  //   let internalFormat = gl.RGBA;
  //   let border = 0;
  //   let format = gl.RGBA;
  //   let type = gl.UNSIGNED_BYTE;
  //   let data = null;
  //   gl.texImage2D(
  //     gl.TEXTURE_2D,
  //     level,
  //     internalFormat,
  //     canvas.width,
  //     canvas.height,
  //     border,
  //     format,
  //     type,
  //     data
  //   );
  //
  //   // Set the view port
  //   gl.viewport(0, 0, width, height);
  // };
  module.addParticle = function(x, y, colour) {
    let pid = dynamicIndex * 2;
    dynamicPositions[pid] = x;
    dynamicPositions[pid + 1] = y;
    dynamicColours[dynamicIndex] = colour;
    dynamicIndex++;
  };
  module.addStaticParticle = function(x, y, colour) {
    let pid = staticIndex * 2;
    staticPositions[pid] = x;
    staticPositions[pid + 1] = y;
    staticColours[staticIndex] = colour;
    staticIndex++;
  };
  module.addImageAboveParticles = function(image, index) {};
  module.addImageBelowParticles = function(image) {
    if (image) {
      //load image as string
      if (typeof image === 'string') {
        let img = new Image();
        img.addEventListener('load', function() {
          texture_bg_info = {
            width: img.width,
            height: img.height,
            img: img,
            texture: texture_bg,
          };
          //bind and upload
          gl.bindTexture(gl.TEXTURE_2D, texture_bg);
          gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, img);
        });
        img.src = image;
      } else {
        //TODO use canvas as image source e.i:
        //gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, canvas);
        //TODO use Image as source
      }
    } else {
      texture_bg_info = null;
    }
  };
  module.clear = function() {
    gl.clear(gl.COLOR_BUFFER_BIT);
  };
  module.render = function() {
    // render to our targetTexture by binding the framebuffer
    gl.bindFramebuffer(gl.FRAMEBUFFER, rttFramebuffer);

    // Tell WebGL how to convert from clip space to pixels
    gl.viewport(0, 0, width, height);
    //gl.clearColor(0,0,0,0);
    //clear();

    //draw static particles
    renderStaticParticles();

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    // Tell WebGL how to convert from clip space to pixels
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    gl.clearColor(0, 0, 0, 1);
    this.clear();

    //render image
    if (texture_bg_info) {
      renderQuad(texture_bg);
    }

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    renderQuad(rttTexture, true);
    //gl.blendFunc(gl.SRC_COLOR, gl.DST_COLOR);
    gl.blendFunc(gl.ONE, gl.ZERO);

    gl.useProgram(program);

    // Bind vertex buffer object
    gl.bindBuffer(gl.ARRAY_BUFFER, dynamicPositionBuffer);
    // Pass the vertex data to the buffer
    gl.bufferData(gl.ARRAY_BUFFER, dynamicPositions, gl.DYNAMIC_DRAW);

    let positionLocation = gl.getAttribLocation(program, 'position');
    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(
      positionLocation, // index
      2, // number of components per element
      gl.FLOAT, // type of data
      false, // normalized
      0, // stride
      0
    ); // offset

    //colours
    gl.bindBuffer(gl.ARRAY_BUFFER, dynamicColourBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, dynamicColours, gl.DYNAMIC_DRAW);
    let colourLocation = gl.getAttribLocation(program, 'colour');
    gl.enableVertexAttribArray(colourLocation);
    gl.vertexAttribPointer(
      colourLocation, // index
      4, // number of components per element
      gl.UNSIGNED_BYTE, // type of data
      true, // normalized
      0, // stride
      0
    ); // offset

    var resolutionLocation = gl.getUniformLocation(program, 'u_resolution');
    gl.uniform2f(resolutionLocation, width, height);
    /*
		  gl.drawElements(
				gl.LINES,           // what to draw
				3,                  // number of vertices
				gl.UNSIGNED_SHORT,  // type of indices
				0);                 // offset
		*/
    //gl.drawArrays(gl.TRIANGLE_STRIP, 0, 3);

    // gl.disable(gl.BLEND);
    if (dynamicIndex > 0) {
      gl.drawArrays(gl.POINTS, 0, dynamicIndex);
    }

    //reset
    dynamicIndex = 0;
    staticIndex = 0;
  };
  module.toBlob = function(callback, mimeType = 'image/jpeg', qualityArgument = 1) {
    return canvas.toBlob(callback, mimeType, qualityArgument);
  };
  return module;
})();

export default Renderer;
