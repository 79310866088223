import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Title = ({ className, tagName = 'h2', children, small, thin }) => {
  const classNames = cx(styles.title, { [styles.small]: small, [styles.thin]: thin }, className);
  const Tag = tagName;
  return <Tag className={classNames}>{children}</Tag>;
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  thin: PropTypes.bool,
};

Title.defaultProps = {
  small: false,
  thin: false,
};

export default Title;
