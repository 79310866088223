import styled from 'styled-components';
import { color, layout, space } from 'styled-system';

const Image = styled.img`
  ${color}
  ${layout}
  ${space}
`;

export default Image;
