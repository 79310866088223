import React, { useState } from 'react';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// Re-write DD default styles
import './styles.css';

const DropDownMenu = ({ options, onChangeCallback }) => {
  const [selected, setSelected] = useState(options[0]);

  return (
    <div>
      <Dropdown
        value={selected}
        options={options}
        onChange={(option) => {
          setSelected(option);
          onChangeCallback(option.value);
        }}
        defaultOption={selected}
      />
    </div>
  );
};

export default DropDownMenu;
