import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './styles.module.css';

const Footer = ({ secondary }) => {
  return (
    <footer className={cx(styles.footer, { [styles.footer_secondary]: secondary })}>
      <div>
        <div className={styles.copyright}>© {new Date().getFullYear()} Beige Elephant</div>
        <div className={styles.policy}>
          <Link to="/legal/terms">Terms of Use</Link> &{' '}
          <Link to="/legal/privacy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
