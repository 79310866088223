import React, { useState } from 'react';

export const SandboxContext = React.createContext({
  drawing: false,
  toggleDrawing: () => {},
});

export const SandboxContextProvider = ({ children }) => {
  const [drawing, setDrawing] = useState(false);
  const toggleDrawing = () => setDrawing(!drawing);

  return (
    <SandboxContext.Provider value={{ drawing, toggleDrawing }}>{children}</SandboxContext.Provider>
  );
};
