class RGB {
  static random() {
    return new RGB(Math.floor(Math.random() * 0xffffff));
  }

  r;
  g;
  b;

  // TODO: Make an RGB pool

  get color() {
    return (this.r << 16) | (this.g << 8) | this.b;
  }

  set color(value) {
    this.r = (value >> 16) & 0xff;
    this.g = (value >> 8) & 0xff;
    this.b = value & 0xff;
  }

  get rgba() {
    return `rgba(${this.r},${this.g},${this.b},1)`;
  }

  constructor(color = 0) {
    this.color = color;
  }
}

export default RGB;
