import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import spinner from 'static/rings-spinner.svg';

const Spinner = ({ centered }) => {
  const classNames = cx(styles.spinner, { [styles.centered]: centered });
  return (
    <div className={classNames}>
      <img src={spinner} alt="spinner" />
    </div>
  );
};

Spinner.propTypes = {
  centered: PropTypes.bool,
};

Spinner.defaultProps = {
  centered: false,
};

export default Spinner;
