export default class Rndm {
  _seed;
  _currentSeed;

  // seed = Math.random()*0xFFFFFF; // sets a random seed
  // seed = 50; // sets a static seed
  get seed() {
    return this._seed;
  }

  set seed(value) {
    this._seed = this._currentSeed = value;
  }

  constructor(seed = 1) {
    this.seed = seed;
  }

  // random(); // returns a number between 0-1 exclusive.
  random = () =>
    (this._currentSeed = (this._currentSeed * 16807) % 2147483647) /
      0x7fffffff +
    0.000000000233;

  // reset(); // resets the number series, retaining the same seed
  reset = () => (this._seed = this._currentSeed);
}
