import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Views
import FaqView from '../views/FaqView';
import HomeView from '../views/HomeView';
import Login from 'views/Login';
import Logout from 'views/Logout';
import NotFoundView from '../views/NotFoundView';
import PieceView from '../views/PieceView';
import PolicyView from '../views/PolicyView';
import GeneralPrivacyPolicyView from '../views/GeneralPrivacyPolicyView';
import MobileApplicationPrivacyPolicyView from '../views/MobileApplicationPrivacyPolicyView';
import PopularView from '../views/PopularView';
import QuickAdmin from 'views/QuickAdmin';
import RecentView from '../views/RecentView';
import RemoveAccountView from '../views/RemoveAccountView';
import ResetPasswordView from '../views/ResetPasswordView';
import RulesView from '../views/RulesView';
import TermsView from '../views/TermsView';

export default function router() {
  return (
    <Switch>
      <Route exact path="/" component={HomeView} />
      <Route exact path="/faq" component={FaqView} />
      <Route exact path="/recent" component={RecentView} />
      <Route exact path="/popular" component={PopularView} />
      <Route exact path="/piece/:id" component={PieceView} />
      <Route
        exact
        path="/account/reset-password/:passwordResetToken"
        component={ResetPasswordView}
      />
      <Route exact path="/account/remove" component={RemoveAccountView} />
      <Route
        exact
        path="/legal/terms"
        component={() => (
          <RulesView>
            <TermsView />
          </RulesView>
        )}
      />
      <Route
        exact
        path="/legal/privacy"
        component={() => (
          <RulesView>
            <PolicyView />
          </RulesView>
        )}
      />
      <Route
        exact
        path="/legal/general-privacy-policy"
        component={() => (
          <GeneralPrivacyPolicyView />
        )}
      />
      <Route
        exact
        path="/legal/mobile-application-privacy-policy"
        component={() => (
          <MobileApplicationPrivacyPolicyView />
        )}
      />
      {/*<Route exact path="/quick-admin/pieces/:id/archive" component={() => {}} />*/}
      {/*<Route exact path="/quick-admin/pieces/:id/clear-caption" component={() => {}} />*/}
      {/*<Route exact path="/quick-admin/pieces/:id/reset-username" component={() => {}} />*/}
      <Route exact path="/quick-admin" component={QuickAdmin} />
      <Route exact path="/quick-admin/pieces/:id/unarchive" component={QuickAdmin} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/logout" component={Logout} />
      <Route component={NotFoundView} />
    </Switch>
  );
};
