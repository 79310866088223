import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Main = ({ children, hidden, secondary }) => {
  const classNames = cx(styles.main, { [styles.hidden]: hidden, [styles.secondary]: secondary });
  return <main className={classNames}>{children}</main>;
};

Main.propTypes = {
  hidden: PropTypes.bool,
  secondary: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Main.defaultProps = {
  hidden: false,
  secondary: false,
};

export default Main;
