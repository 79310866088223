import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Link = ({ strong, children, onClick, href, warning }) => {
  const classNames = cx(styles.link, { [styles.strong]: strong, [styles.warning]: warning });

  if (href) {
    return (
      <a href={href} onClick={onClick} className={classNames}>
        {children}
      </a>
    );
  }

  return (
    <div onClick={onClick} className={classNames}>
      {children}
    </div>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  strong: PropTypes.bool,
  onClick: PropTypes.func,
  warning: PropTypes.bool,
  children: PropTypes.string.isRequired,
};

Link.defaultProps = {
  warning: false,
  strong: false,
  href: '',
};

export default Link;
