import React, { useState } from 'react';

export const SettingsContext = React.createContext({
  sound: false,
  autoFlow: false,
});

export const SettingsContextProvider = ({ children }) => {
  const savedSound = JSON.parse(localStorage.getItem('sound'));
  const [sound, setSound] = useState(savedSound !== null ? savedSound : true);
  const toggleSound = () => {
    localStorage.setItem('sound', !sound);
    setSound(!sound);
  };

  const savedAutoFlow = JSON.parse(localStorage.getItem('autoFlow'));
  const [autoFlow, setAuthFlow] = useState(savedAutoFlow !== null ? savedAutoFlow : true);
  const toggleAutoFlow = () => {
    localStorage.setItem('autoFlow', !autoFlow);
    setAuthFlow(!autoFlow);
  };

  return (
    <SettingsContext.Provider value={{ sound, toggleSound, autoFlow, toggleAutoFlow }}>
      {children}
    </SettingsContext.Provider>
  );
};
