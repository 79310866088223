import React, { useState } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input';
import Title from 'components/Title';
import callBackend from 'common/functions/callBackend';

import styles from './styles.module.css';

const API_URL = process.env.REACT_APP_API_URL;
const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 254;

const ResetPasswordView = ({ match }) => {
  const [errorMessage, setErrorMessage] = useState();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [success, setSuccess] = useState(false);

  const { passwordResetToken } = match.params;

  const resetPassword = async () => {
    if (password !== passwordConfirm) {
      setErrorMessage('Please make sure passwords match.');
      return;
    }

    if (password.length < PASSWORD_MIN_LENGTH) {
      setErrorMessage(`Please make sure password has minimum ${PASSWORD_MIN_LENGTH} characters.`);
      return;
    }

    if (password.length > PASSWORD_MAX_LENGTH) {
      setErrorMessage(`Please make sure password has maximum ${PASSWORD_MAX_LENGTH} characters.`);
      return;
    }

    const formData = new FormData();
    formData.append('password', password);
    formData.append('passwordResetToken', passwordResetToken);

    const { response, error } = await callBackend({
      method: 'POST',
      headers: null,
      url: `${API_URL}/password-reset/reset`,
      body: formData,
    });

    if (error || (response && response.errors)) {
      setErrorMessage('Password reset failed :(');
      setSuccess(false);
    } else {
      setSuccess(true);
    }
  };

  return success ? (
    <div className={styles.container}>
      <Title>Reset password</Title>

      <p className={styles.message}>
        Password was reset successfully, please login with Thisissand app.
      </p>
    </div>
  ) : (
    <div className={styles.container}>
      <Title>Reset password</Title>

      <p className={cx(styles.message, errorMessage && styles.errorMessage)}>
        {errorMessage || 'Please provide a new password.'}
      </p>

      <Input
        className={styles.input}
        type="password"
        value={password}
        onChange={(e) => {
          if (e.target.value.length >= 254) return;
          setPassword(e.target.value);
        }}
        placeholder="New password"
      />

      <Input
        className={styles.input}
        type="password"
        value={passwordConfirm}
        onChange={(e) => {
          if (e.target.value.length >= 254) return;
          setPasswordConfirm(e.target.value);
        }}
        placeholder="Confirm new password"
      />

      <Button ghost={true} onClick={resetPassword}>
        Reset
      </Button>
    </div>
  );
};

export default withRouter(ResetPasswordView);
