import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import Span from 'components/Span';

import styles from './styles.module.css';

const Button = ({ children, disabled, ghost, warning, className, ...props }) => {
  const classNames = cx(
    styles.button,
    {
      [styles.disabled]: disabled,
      [styles.ghost]: ghost,
      [styles.warning]: warning,
    },
    className
  );

  return (
    <Span className={classNames} {...props}>
      {children}
    </Span>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  warning: PropTypes.bool,
  children: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  ghost: false,
  warning: false,
  children: 'Button',
};

export default Button;
