import React, { useEffect } from 'react'
import cx from 'classnames'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import Button from '../Button'
import styles from './styles.module.css'
import DiscardModal from './components/Discard'
import SaveModal from './components/Save'
import { useGoogleAnalytics4 } from 'context/GoogleAnalytics4Context'

const Modal = React.forwardRef(
  (
    { id, opened, header, actions, onDismiss, children, component, secondary, shadow, cover },
    ref
  ) => {
    const ga4 = useGoogleAnalytics4()

    useEffect(() => {
      if (opened && id) {
        ga4.trackModalView(id)
        return () => ga4.trackModalView()
      }
    }, [opened])

    if (!opened) return null

    return ReactDOM.createPortal(
      <div ref={ref} className={cx(styles.cover, { [styles.cover_transparent]: !cover })}>
        <div
          className={styles.box}
          onClick={e => {
            e.stopPropagation()
            onDismiss()
          }}
        >
          {component ? (
            component
          ) : (
            <div
              className={cx(styles.modal, {
                [styles.secondary]: secondary,
                [styles.modal_shadow]: shadow,
              })}
              onClick={e => e.stopPropagation()}
            >
              {header && <div className={styles.header}>{header}</div>}
              <div>{children}</div>
              <div className={styles.actions}>{actions}</div>
            </div>
          )}
        </div>
      </div>,
      document.getElementById('modal')
    )
  }
)

Modal.propTypes = {
  opened: PropTypes.bool,
  header: PropTypes.string,
  children: PropTypes.node,
  component: PropTypes.node,
  secondary: PropTypes.bool,
  actions: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([Button]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf([Button]),
      })
    ),
  ]),
  onDismiss: PropTypes.func,
}

Modal.defaultProps = {
  header: '',
  opened: false,
  actions: null,
  type: 'default',
  alignment: 'row',
  secondary: false,
  cover: true,
  shadow: true,
}

export { DiscardModal, SaveModal }
export default Modal
