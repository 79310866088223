import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Username = ({ children, small }) => {
  const classNames = cx(styles.username, { [styles.small]: small });
  return <span className={classNames}>{children}</span>;
};

Username.propTypes = {
  children: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

Username.defaultProps = {
  small: false,
};

export default Username;
