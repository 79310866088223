import { useEffect, useState } from 'react';

import callBackend from 'common/functions/callBackend';

const API_URL = process.env.REACT_APP_API_URL;

const useLoginApi = () => {
  const [error, setError] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    if (status !== 'complete') return;
    setStatus('idle');
  }, [status]);

  const login = async ({ facebookAccessToken, usernameOrEmail, password }) => {
    setStatus('loading');

    const formData = new FormData();
    usernameOrEmail && formData.append('usernameOrEmail', usernameOrEmail);
    password && formData.append('password', password);
    facebookAccessToken && formData.append('facebookAccessToken', facebookAccessToken);

    const request = await callBackend({
      method: 'POST',
      headers: null,
      url: `${API_URL}/auth/${facebookAccessToken ? 'facebook' : 'login'}`,
      body: formData,
    });

    if (!request.response.auth_token || !request.response.user) {
      setError('Invalid login credentials.');
      setStatus('complete');
      return;
    }

    setResponse(request.response);
    setError(undefined);
    setStatus('complete');
  };

  return { error, login, response, status };
};

export default useLoginApi;
