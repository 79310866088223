import { useEffect, useState } from 'react';

import callBackend from 'common/functions/callBackend';

const API_URL = process.env.REACT_APP_API_URL;

const useLoginApi = () => {
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    if (status !== 'complete') return;
    setStatus('idle');
  }, [status]);

  const logout = async () => {
    setStatus('loading');

    await callBackend({
      method: 'GET',
      headers: null,
      url: `${API_URL}/auth/logout`,
    });

    setStatus('complete');
  };

  return { logout, status };
};

export default useLoginApi;
