import { useEffect, useState } from 'react';

import callBackend from 'common/functions/callBackend';

const API_URL = process.env.REACT_APP_API_URL;

const useUserApi = () => {
  const [error, setError] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    if (status !== 'complete') return;
    setStatus('idle');
  }, [status]);

  const getUser = async ({ authToken }) => {
    setStatus('loading');

    const request = await callBackend({
      method: 'GET',
      url: `${API_URL}/users/me`,
      headers: { Authorization: 'JWT ' + authToken },
    });

    if (!request.response) {
      setError('Invalid user request response.');
      setStatus('complete');
      return;
    }

    setResponse(request.response);
    setError(undefined);
    setStatus('complete');
  };

  return { error, getUser, response, status };
};

export default useUserApi;
