import { useEffect } from 'react';
import { getGalleryQueryString } from 'helpers/api/getGalleryQueryString';
import { getPieces } from 'helpers/api/get';
import { formatPieceParams } from 'helpers';

export const useGallery = ({ type, items, pageNumber, createdAtBegin }, callback) => {
  useEffect(() => {
    (async () => {
      const galleryQueryString = getGalleryQueryString(type, items, pageNumber, createdAtBegin);
      const fetchedPieces = await getPieces(galleryQueryString);
      // Execute a callback when pieces has been fetched
      callback(fetchedPieces.map((piece) => formatPieceParams(piece)));
    })();
  }, [pageNumber, createdAtBegin]);
};
