import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Components
import Title from 'components/Title';

import styles from './styles.module.css';

const Text = ({ id, title, titleTagName, children, secondary, isHtml }) => {
  const classNamesParagraph = cx({ [styles.secondary]: secondary });

  return (
    <div id={id} className={styles.text}>
      {title && (
        <Title tagName={titleTagName} thin={true}>
          {title}
        </Title>
      )}
      {!isHtml ? (
        <p className={classNamesParagraph}>{children}</p>
      ) : (
        <p
          className={classNamesParagraph}
          dangerouslySetInnerHTML={{
            __html: children,
          }}
        />
      )}
    </div>
  );
};

Text.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
  title: PropTypes.string,
  titleTagName: PropTypes.string,
  isHtml: PropTypes.bool,
};

Text.defaultProps = {
  secondary: false,
  isHtml: false,
  title: '',
};

export default Text;
