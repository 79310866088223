import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Logo from 'components/Logo';
import Button from 'components/Button';
import styles from './styles.module.css';

const Header = ({ hidden, secondary, className, hidePlayNow, onLogoClick, onPlayNowClick }) => {
  const classNames = cx(
    styles.header,
    { [styles.hidden]: hidden, [styles.secondary]: secondary },
    className
  );

  return (
    <header className={classNames}>
      <div className={styles.content}>
        <Link to="/" onClick={onLogoClick}>
          <Logo />
        </Link>

        {!hidePlayNow && (
          <Button className={styles.button} onClick={onPlayNowClick}>
            Play Now
          </Button>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  hidden: PropTypes.bool,
  secondary: PropTypes.bool,
};

Header.defaultProps = {
  hidden: false,
  secondary: false,
};

export default Header;
