import React from 'react';
import PropTypes from 'prop-types';

// Components
import Title from 'components/Title';
import Button from 'components/Button';

import styles from './styles.module.css';

const DiscardModal = ({
  onCancel,
  onSuccess,
  children,
  title,
  successButtonTitle,
  warning = true,
}) => {
  return (
    <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
      <Title small={true}>{title}</Title>
      <div className={styles.content}>{children}</div>
      <div className={styles.buttons}>
        {onCancel && (
          <Button ghost={true} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button warning={warning} onClick={onSuccess}>
          {successButtonTitle}
        </Button>
      </div>
    </div>
  );
};

DiscardModal.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  successButtonTitle: PropTypes.string,
};

DiscardModal.defaultProps = {
  title: '',
  successButtonTitle: 'Discard',
  onSuccess: () => {},
};

export default DiscardModal;
