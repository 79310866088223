import { useCallback, useRef } from 'react';

const maxRetryCount = 4;

export function useRetryImageLoading() {
  const retryCountRef = useRef(0);

  const retryImageLoading = useCallback((imgRef, originalImgSrc) => {
    if (retryCountRef.current >= maxRetryCount) {
      return false;
    }

    setTimeout(() => {
      const url = new URL(originalImgSrc);
      url.searchParams.set('t', new Date().getTime());
      imgRef.current.src = url;

      retryCountRef.current += 1;
    }, 1000);

    return true;
  }, [retryCountRef]);

  return retryImageLoading;
}
