import React, { useRef } from 'react';
import styled from 'styled-components';

import Box from 'components/Box';
import { getPieceImageUrl } from 'helpers/api/getPieceImageUrl';
import { useRetryImageLoading } from 'hooks/useRetryImageLoading';

const Thumbnail = ({ onClick, onError, piece }) => {
  const imgRef = useRef(null)

  const retryImageLoading = useRetryImageLoading();

  const src = getPieceImageUrl(piece.id, 280);

  return (
    <Box position="relative" pt="100%" h={0} overflow="hidden">
      <Picture onClick={onClick}>
        <img
          alt={piece.username}
          onError={(event) => {
            if (!retryImageLoading(imgRef, src)) {
              onError(event);
            }
          }}
          ref={imgRef}
          src={src}
        />
      </Picture>
    </Box>
  );
};

const Picture = styled.picture`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default Thumbnail;
