import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { color, layout, space, typography } from 'styled-system';

import styles from './styles.module.css';

const Textarea = ({ error, ghost, onBlur, onChange, placeholder, rows, type, value, ...props }) => {
  const classNames = cx(styles.textarea, {
    [styles.ghost]: ghost,
    [styles.error]: error,
    [styles.ghost_error]: ghost && error,
  });

  return (
    <StyledTextarea
      className={classNames}
      type={type}
      value={value}
      placeholder={placeholder}
      rows={rows}
      onBlur={onBlur}
      onChange={onChange}
      {...props}
    />
  );
};

const StyledTextarea = styled.textarea`
  ${color}
  ${layout}
  ${space}
  ${typography}
`;

Textarea.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  ghost: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  rows: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

Textarea.defaultProps = {
  error: false,
  ghost: false,
  rows: '2',
  type: 'text',
};

export default Textarea;
