import React from 'react';

// Components
import Text from 'components/Text';
import Title from 'components/Title';

// TODO:
// Working prototype. Need to think how to make it better.
// Recursion or divide into different components?
export const termsPolicyRenderer = content => {
  const renderOrderedList = (content, secondary) =>
    secondary ? renderListItems(content, 'ol') : <ol>{renderListItems(content, 'ol')}</ol>;

  const renderUnorderedList = (content, secondary) =>
    secondary ? renderListItems(content, 'ul') : <ul>{renderListItems(content, 'ul')}</ul>;

  const renderRomanList = (content, secondary) =>
    secondary ? (
      renderListItems(content, 'roman')
    ) : (
      <ol className='ol_roman'>{renderListItems(content, 'roman')}</ol>
    );

  const renderListItems = (listItems, listType) => {
    if (listItems[0].title) {
      return listItems.map((item, i) => {
        return (
          <div key={i}>
            <div>{item.title}</div>
            {listType === 'ol' && renderOrderedList(item.content, true)}
            {listType === 'ul' && renderUnorderedList(item.content, true)}
            {listType === 'roman' && renderRomanList(item.content, true)}
          </div>
        );
      });
    }

    return listItems.map(li => {
      if (Array.isArray(li)) {
        return (
          <ol key={li} className='ol_latin'>
            {renderOrderedList(li, true)}
          </ol>
        );
      }

      return (
        <li key={li}>
          <div
            dangerouslySetInnerHTML={{
              __html: li
            }}
          />
        </li>
      );
    });
  };

  return content.map((contentEntry, index) => {
    const { title, format, subtitle, summary, content, description } = contentEntry;

    return (
      <div key={index}>
        <Text title={title} secondary={true}>
          {subtitle && <Title small={true}>{subtitle}</Title>}
          {description && (
            <div
              dangerouslySetInnerHTML={{
                __html: `<p>${description}</p>`
              }}
            />
          )}
          {format === 'ol' && renderOrderedList(content)}
          {format === 'ul' && renderUnorderedList(content)}
          {format === 'roman' && renderRomanList(content)}
          {summary && <p>{summary}</p>}
        </Text>
      </div>
    );
  });
};
