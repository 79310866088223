import { useConfig } from 'context/config'
import useEffectOnMountOnly from 'hooks/useEffectOnMountOnly'
import React, { createContext, useContext, useEffect, useState, useRef } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

const GoogleAnalytics4Context = createContext(undefined)

export const GoogleAnalytics4Provider = ({ children }) => {
  const { googleAnalytics } = useConfig()

  const location = useLocation()

  const [pathname, setPathname] = useState(undefined)
  const [modalNamesChanged, setModalNamesChanged] = useState(false)

  // Keep modal names in ref so they can be updated multiple times per update
  const modalNamesRef = useRef([])

  useEffectOnMountOnly(() => {
    // Consent-related Google Analytics configuration. More info at the following webpages:
    //
    // * https://support.google.com/tagmanager/answer/13802165?hl=en&ref_topic=14226291&sjid=249987320531447472-EU
    // * https://developers.google.com/tag-platform/gtagjs/reference#consent
    ReactGA._gtag('consent', 'default', {
      // GA should not set cookies or device identifiers related to advertising.
      ad_storage: 'denied',
      // GA should behave as if the user did not consent to sending user data to Google for advertising purposes.
      ad_user_data: 'denied',
      // GA should behave as if the user did not consent to personalised advertising.
      ad_personalization: 'denied',
      // Setting analytics_storage to denied instructs GA to not set any cookies
      // related to analytics. GA documentation says that it then uses cookieless
      // pings which it indeed does. Google Tag Assistant shows those pings without
      // any issue. However, Google Analytics real-time view and Google Analytics
      // DebugView do not show any activity when cookieless pings are used. They
      // immediately show activity when analytics_storage is switched to
      // granted. It would seem like cookieless pings do not work.
      analytics_storage: 'granted',
      // GA should not store information related to personalisation.
      personalization_storage: 'denied',
    })

    ReactGA.initialize(googleAnalytics.measurementId)
  }, [])

  useEffect(() => {
    pathname !== location.pathname && setPathname(location.pathname)
  }, [location])

  useEffect(() => {
    pathname && trackPageView()
  }, [pathname])

  useEffect(() => {
    if (modalNamesChanged) {
      pathname && trackPageView()
      setModalNamesChanged(false)
    }
  }, [modalNamesChanged])

  const trackPageView = (pathname) => {
    ReactGA.send({
      hitType: "pageview", page:
        `${pathname}${modalNamesRef.current.length && pathname !== '/' ? '/' : ''
        }${modalNamesRef.current.join('/')}`
    }
    )
  }

  const trackModalView = name => {
    if (name) {
      modalNamesRef.current = [...modalNamesRef.current, name]
    } else {
      modalNamesRef.current = modalNamesRef.current.slice(0, modalNamesRef.current.length - 1)
    }
    setModalNamesChanged(true)
  }

  return (
    <GoogleAnalytics4Context.Provider value={{ trackEvent: ReactGA.event, trackModalView }}>
      {children}
    </GoogleAnalytics4Context.Provider>
  )
}

export const useGoogleAnalytics4 = () => {
  const context = useContext(GoogleAnalytics4Context)
  if (!context) {
    throw new Error('Trying to use useGoogleAnalytics4 without GoogleAnalytics4Provider')
  }
  return context
}
