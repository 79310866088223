import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import Flex from 'components/Flex';

import styles from './styles.module.css';

const Hero = ({ children, hidden, ...props }) => {
  const classNames = cx(styles.hero, { [styles.hidden]: hidden });

  return (
    <Flex
      position="absolute"
      bottom={0}
      fullWidth
      height="47%"
      column
      alignCenter
      justifyContent="space-between"
      padding="2em"
      className={classNames}
      {...props}
    >
      {children}
    </Flex>
  );
};

Hero.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Hero.defaultProps = {
  hidden: false,
};

export default Hero;
