import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Toggle = ({ onClick, enabled }) => {
  return (
    <label className={styles.toggle}>
      <input type="checkbox" onChange={onClick} checked={enabled} />
      <span className={styles.slider} />
    </label>
  );
};

Toggle.propTypes = {
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Toggle.defaultProps = {
  enabled: false,
};

export default Toggle;
