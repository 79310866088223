import { useEffect, useState } from 'react';
import { updateCanvasSize } from 'helpers';
import { useWindowSize } from '../useWindowSize';
import { RGB } from 'core';

const useColorPaletteCanvas = () => {
  const [canvas, setCanvas] = useState();
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!canvas) return;

    if (typeof Image !== 'undefined') {
      const image = new Image();
      image.onload = () => setImage(image);
      image.src = 'colorpalette.jpg';
    }
  }, [canvas]);

  useEffect(() => draw(), [image]);

  useEffect(() => {
    if (visible) draw();
  }, [visible]);

  useEffect(() => {
    updateCanvasSize(canvas, { width: windowSize.width, height: windowSize.height });

    draw();
  }, [canvas, windowSize]);

  const draw = () => {
    if (!canvas || !image) return;

    if (visible) {
      canvas.getContext('2d').drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
      canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const getRGB = ({ x, y }) => {
    let colorData = canvas.getContext('2d').getImageData(x, y, 1, 1).data;
    let rgb = new RGB();
    rgb.r = colorData[0];
    rgb.g = colorData[1];
    rgb.b = colorData[2];
    return rgb;
  };

  return { visible, setVisible, setCanvas, getRGB };
};

export { useColorPaletteCanvas };
