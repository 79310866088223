import { updateCanvasSize } from 'helpers';
import { ParticleEngine, Renderer } from 'core';
import { useWindowSize } from './useWindowSize';
import sandboxBackground from 'static/sandbox.png';
import { useState, useEffect, useRef } from 'react';
import { useSandboxInteraction } from './useSandboxInteraction';

let timer = null;

const useSandbox = ({
  getRGB,
  requestPiece,
  sound,
  autoFlow,
  autoFlowMode,
  setAutoFlowMode,
  activated,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [particleEngine] = useState(ParticleEngine);
  const [renderer] = useState(Renderer);
  const { canvas, setCanvas, currentPoint, setEnabled } = useSandboxInteraction({
    autoFlow,
    autoFlowMode,
    setAutoFlowMode,
    muted: !sound,
    activated,
  });

  const windowSize = useWindowSize();
  const [sandboxSize, setSandboxSize] = useState(null);

  const [piece, setPiece] = useState(null);
  const [blob, setBlob] = useState(null);

  const updateAndRenderRef = useRef();
  updateAndRenderRef.current = {
    canvas,
    currentPoint,
    renderer,
    particleEngine,
    sandboxSize,
    getRGB,
    setPiece,
    setBlob,
    requestPiece,
  };

  useEffect(() => {
    if (!canvas) return () => {};

    updateCanvasSize(canvas, windowSize);
    setSandboxSize(windowSize);

    renderer.initialize(canvas);
    particleEngine.initialize(windowSize);

    renderer.addImageBelowParticles(sandboxBackground);
    renderer.renderImageToStaticTexture(canvas);

    setInitialized(true);
  }, [canvas]);

  useEffect(() => {
    if (!initialized) return () => {};

    timer = setInterval(() => {
      updateAndRender(updateAndRenderRef);
    }, 1000 / 60);

    return () => clearInterval(timer);
  }, [initialized, requestPiece]);

  useEffect(() => {
    setEnabled(true);

    if (!sound) {
      particleEngine.mute();
    } else {
      particleEngine.unmute();
    }
  }, [setEnabled, sound]);

  const updateAndRender = ({ current }) => {
    const {
      currentPoint,
      renderer,
      particleEngine,
      getRGB,
      setBlob,
      setPiece,
      requestPiece,
    } = current;
    if (currentPoint) {
      particleEngine.addParticles(currentPoint, getRGB);
    }

    particleEngine.update(renderer);
    renderer.render();

    if (requestPiece) {
      clearInterval(timer);
      timer = null;

      renderer.toBlob((blob) => {
        const urlCreator = window.URL || window.webkitURL;
        setPiece(urlCreator.createObjectURL(blob));
        setBlob(blob);
      });
    }
  };

  const resetCanvas = () => {
    updateCanvasSize(canvas, windowSize);
    setBlob(null);
    setPiece(null);
    renderer.reset();
    particleEngine.reset(windowSize);
    setSandboxSize(windowSize);
  };

  const isReadyToSubmit = () => {
    const percentComplete =
      particleEngine.getParticlesTotal() / (sandboxSize.width * sandboxSize.height);
    return percentComplete > 0.2;
  };

  return {
    blob,
    piece,
    setCanvas,
    resetCanvas,
    isReadyToSubmit,
  };
};

export { useSandbox };
