import React, { useContext } from 'react';

import Icon from 'components/Icon';
import transitions from 'styles/transitions';
import { ColorPaletteContext } from 'hooks/ColorPalette/ColorPaletteContext';

const ColorPalette = () => {
  const transitionEase = transitions.regular.ease;
  const transitionDuration = transitions.regular.duration;

  const { visible, setVisible, setCanvas, selectRandomRGBs } = useContext(ColorPaletteContext);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        transform: `translateY(${visible ? 0 : 100}vh)`,
        transition: `transform  ${transitionDuration} ${transitionEase}`,
        willChange: 'transform',
      }}
    >
      <canvas ref={setCanvas} onMouseUp={() => setVisible(false)} />

      <Icon
        name="circleFull"
        style={{
          position: 'absolute',
          top: '4rem',
          left: '4rem',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          opacity: visible ? 1 : 0,
          cursor: 'pointer',
          userSelect: 'none',
          transition: `opacity ${transitionDuration} ${transitionEase}`,
          willChange: 'opacity',
        }}
        onClick={() => selectRandomRGBs()}
      />
    </div>
  );
};

export default ColorPalette;
