import { useEffect, useState } from 'react';

import callBackend from 'common/functions/callBackend';
import { useUser } from 'context/UserContext';

const API_URL = process.env.REACT_APP_API_URL;

const useUnarchive = () => {
  const [error, setError] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const [status, setStatus] = useState('idle');

  const { authToken, user } = useUser();

  useEffect(() => {
    if (status === 'complete') {
      setStatus('idle');
    }
  }, [status]);

  const archive = async ({ piece }) => {
    setStatus('loading');

    if (!authToken || !user || user.role !== 'admin') {
      setError('Invalid credentials.');
      setStatus('complete');
      return;
    }

    if (!piece) {
      setError('Missing piece.');
      setStatus('complete');
      return;
    }

    const request = await callBackend({
      method: 'POST',
      url: `${API_URL}/pieces/${piece.id}/disapprove-auto-archival`,
      authHeader: { Authorization: 'JWT ' + authToken },
    });

    if (request.status !== 204) {
      setError('Unarchiving failed.');
      setStatus('complete');
      return;
    }

    setResponse({ success: true });
    setError(undefined);
    setStatus('complete');
  };

  const unarchive = async ({ piece }) => {
    setStatus('loading');

    if (!authToken || !user || user.role !== 'admin') {
      setError('Invalid credentials.');
      setStatus('complete');
      return;
    }

    if (!piece) {
      setError('Missing piece.');
      setStatus('complete');
      return;
    }

    const request = await callBackend({
      method: 'PUT',
      url: `${API_URL}/pieces/${piece.id}`,
      authHeader: { Authorization: 'JWT ' + authToken },
      body: { autoArchivedAt: null, archivedAt: null },
    });

    if (request.status !== 204) {
      setError('Unarchiving failed.');
      setStatus('complete');
      return;
    }

    setResponse({ success: true });
    setError(undefined);
    setStatus('complete');
  };

  return { archive, error, unarchive, response, status };
};

export default useUnarchive;
