import React from 'react'
import MarketBanner from 'components/MarketBanner'

import BannerImg from 'static/bannerTheBotanist.jpg'
import Flex from 'components/Flex'
import Title from 'components/Title'
import { useGoogleAnalytics4 } from 'context/GoogleAnalytics4Context'

import styles from './styles.module.css'

const BotanistBanner = () => {
  const ga4 = useGoogleAnalytics4()

  return (
    <div className={styles.banner}>
      <img className={styles.image} src={BannerImg} alt="Thisissand app icon" />

      <div className={styles.content}>
        <Title>The Botanist</Title>

        <p>
          If you like Thisissand, you should also give your creativity a go on The Botanist app!{' '}
        </p>

        <Flex mt="2em">
          <MarketBanner
            small={true}
            type="ios"
            alt="Apple Store"
            url="https://apps.apple.com/us/app/the-botanist/id1536991175"
            onClick={() => {
              const event = {
                category: 'link',
                action: 'follow-the-botanist-app-link',
                label: 'app-store',
              }
              ga4.trackEvent(event)
            }
            }
          />

          <MarketBanner
            ml="1em"
            small={true}
            type="android"
            alt="Google Play"
            url="https://play.google.com/store/apps/details?id=app.thebotanist.thebotanist"
            onClick={() => {
              const event = {
                category: 'link',
                action: 'follow-the-botanist-app-link',
                label: 'google-play',
              }
              ga4.trackEvent(event)
            }
            }
          />
        </Flex>
      </div>
    </div>
  )
}

export default BotanistBanner
