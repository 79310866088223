import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color, layout, space, typography } from 'styled-system';
import styles from './styles.module.css';

const Input = ({
  className,
  placeholder,
  onChange,
  ghost,
  value,
  type,
  error,
  onBlur,
  ...props
}) => {
  const classNames = cx(className, styles.input, {
    [styles.ghost]: ghost,
    [styles.error]: error,
    [styles.ghost_error]: ghost && error,
  });
  return (
    <StyledInput
      type={type}
      value={value}
      onChange={onChange}
      className={classNames}
      onBlur={onBlur}
      placeholder={placeholder}
      {...props}
    />
  );
};

const StyledInput = styled.input`
  ${color}
  ${layout}
  ${space}
  ${typography}
`;

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  ghost: PropTypes.bool,
  type: PropTypes.string,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  ghost: false,
  error: false,
};

export default Input;
