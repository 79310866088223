import React from 'react';

// Components
import Text from 'components/Text';
import Title from 'components/Title';

import styles from './styles.module.css';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    if (this.state.error !== undefined) {
      return (
        <div className={styles.container}>
          <Title>Oops, an error occurred. :(</Title>

          <Text>{this.state.error.toString()}</Text>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
