import { useEffect } from 'react';

export const useInfiniteScroll = ({ space, callback, loading }) =>
  useEffect(() => {
    if (!loading) {
      const body = window.document.body;
      const content = document.querySelector('#root');

      const calculateSpace = () => {
        const pixelsToBottom = content.scrollHeight - window.scrollY - body.clientHeight;

        if (pixelsToBottom > 0 && pixelsToBottom <= space && !loading) {
          window.removeEventListener('scroll', calculateSpace);
          callback();
        }
      };

      window.addEventListener('scroll', calculateSpace);

      return () => {
        window.removeEventListener('scroll', calculateSpace);
      };
    }
  }, [loading]);
