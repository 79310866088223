import React from 'react';
import cx from 'classnames';

import textStyles from 'components/Text/styles.module.css';

const classNamesParagraph = cx({ [textStyles.secondary]: true });

export function IndentedParagraph({ children }) {
  return <p className={classNamesParagraph}>{children}</p>
}
