import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import Flex from 'components/Flex';
import { useUser } from 'context/UserContext';

const Logout = () => {
  const { logout, logoutStatus, user } = useUser();

  const isLoading = logoutStatus === 'loading';

  return (
    <Flex m="0 auto" maxWidth="480px" p={2} column alignCenter>
      {user ? (
        <Button disabled={isLoading} onClick={!isLoading ? () => logout() : undefined}>
          {isLoading ? 'Processing...' : 'Logout'}
        </Button>
      ) : (
        <Link to="/login">Login</Link>
      )}
    </Flex>
  );
};

export default Logout;
