import { useMediaQuery } from 'react-responsive';

import { useWindowSize } from 'hooks/useWindowSize';

import { breakpointAliases, breakpoints } from 'theme';

const MIN = 0;
const MAX = 100000;

const useBreakpoint = (alias) =>
  useMediaQuery({
    minWidth: breakpointAliases[alias].min || MIN,
    maxWidth: breakpointAliases[alias].max || MAX,
  });

const useBreakpointMin = (alias) =>
  useMediaQuery({ minWidth: breakpointAliases[alias].min || MIN });

const useBreakpointMax = (alias) =>
  useMediaQuery({ maxWidth: breakpointAliases[alias].max || MAX });

const useBreakpointAlias = () => {
  const { width } = useWindowSize();
  const breakpoint = breakpoints.find(
    ({ min, max }) => width >= (min || MIN) && width <= (max || MAX)
  );
  return breakpoint ? breakpoint.alias : undefined;
};

export { useBreakpoint, useBreakpointMin, useBreakpointMax, useBreakpointAlias };
