const cdnUrl = process.env.REACT_APP_CDN_URL;

export function getPieceImageUrl(pieceId, size) {
  if (size !== undefined) {
    return `${cdnUrl}/v1/thumbnails/${pieceId}/${size}`;
  } else {
    const subdirectory = `i${Math.floor(pieceId / 1000) * 1000}`;
    return `${cdnUrl}/v1/originals/${subdirectory}/${pieceId}`;
  }
};
