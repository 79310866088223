import React from 'react';
import { useSpring, animated } from 'react-spring';

import RGB from 'core/RGB';

const BUTTON_RADIUS = 32;

const ColorCircle = ({ rgba, radius, ...props }) => {
  const springProps = useSpring({
    transform: `scale(${radius / BUTTON_RADIUS})`,
    from: { transform: `scale(0)` },
    config: { mass: 1, tension: 400, friction: 18 },
    willChange: 'transform',
  });

  return (
    <animated.div
      style={{
        position: 'absolute',
        width: BUTTON_RADIUS * 2,
        height: BUTTON_RADIUS * 2,
        borderRadius: BUTTON_RADIUS,
        backgroundColor: rgba,
        boxShadow: '0 1px 2px 0 hsla(0, 0%, 0%, 0.1)',
        ...springProps,
      }}
      {...props}
    />
  );
};

const ColorButton = ({
  rgbs = [new RGB(0xffffff)],
  pointerEvents = 'none',
  followPoint,
  rgbKeys = false,
  style,
  ...props
}) => {
  const combinedStyle = {
    cursor: 'pointer',
    position: 'relative',
    pointerEvents: pointerEvents,
    transform: !followPoint
      ? `translate(${-BUTTON_RADIUS}px, ${-BUTTON_RADIUS}px)`
      : `translate(${followPoint.x - BUTTON_RADIUS}px, ${followPoint.y - BUTTON_RADIUS * 2.25}px)`,
    zIndex: 1,
    ...style,
  };

  return (
    <div style={combinedStyle} {...props}>
      {rgbs.map((rgb, index) => {
        const radius = (BUTTON_RADIUS / rgbs.length) * (rgbs.length - index);

        return <ColorCircle key={rgbKeys ? rgb.rgba : index} rgba={rgb.rgba} radius={radius} />;
      })}
    </div>
  );
};

export default ColorButton;
