import React from 'react';
import Box from 'components/Box';

const ContentBox = ({ children, ...props }) => {
  return (
    <Box p={['1em', '1em', '2em']} m="0 auto" maxWidth="1140px" {...props}>
      {children}
    </Box>
  );
};

export default ContentBox;
