import DiscardModal from 'components/Modal/components/Discard';
import React from 'react';

const IncompletePiece = ({ onCloseTrigger }) => {
  return (
    <DiscardModal
      title="Please keep going a bit further!"
      successButtonTitle="Ok"
      warning={false}
      onSuccess={onCloseTrigger}
    />
  );
};

export default IncompletePiece;
