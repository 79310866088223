import React from 'react';
import { Link } from 'react-router-dom';

// Components
import Title from 'components/Title';

import styles from './styles.module.css';

const PolicyView = () => {
  return (
    <div className={styles.container}>
      <Link to="/legal/terms" className={styles.link}>
        Terms of Use
      </Link>
      <Title>Privacy Policy</Title>
      <p>Thisissand website, Thisissand service, or any Thisissand applications (including browser and mobile applications) are made available by Beige Elephant (together, the “Service” or “Thisissand”). The Service is owned or controlled by Beige Elephant Ltd. (“Beige Elephant”, “we”, “us”, “our”, which also includes our employees and other staff).</p>
      <p>The Service has two differently termed privacy policies: the general privacy policy (“General Privacy Policy”) and the mobile application privacy policy (“Mobile Application Privacy Policy”). Collectively they are called “Privacy Policy”. By accessing or using the Service with the Thisissand mobile application you agree to the Mobile Application Privacy Policy. By accessing or using the Service with the Thisissand browser application or any other means besides the Thisissand mobile application you agree to the General Privacy Policy. If you do not agree to the Privacy Policy, please do not access or use the Service.</p>
      <p>The General Privacy Policy can be found at <a href="/legal/general-privacy-policy">https://thisissand.com/legal/general-privacy-policy</a>.</p>
      <p>The Mobile Application Privacy Policy can be found at <a href="/legal/mobile-application-privacy-policy">https://thisissand.com/legal/mobile-application-privacy-policy</a>.</p>
    </div>
  );
};

export default PolicyView;
