import fetch from 'isomorphic-unfetch';

const jsonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const callBackend = async ({ url, method, body, headers = jsonHeaders, authHeader = {} }) => {
  console.log(method, url, body || '');

  let response;
  let responseJSON;

  if (headers === jsonHeaders) {
    body = JSON.stringify(body);
  }

  headers = { ...headers, ...authHeader };

  try {
    response = await fetch(url, {
      method,
      headers: headers ? headers : undefined,
      body,
    });
  } catch (error) {
    return {
      status: 0,
      error,
    };
  }

  if (response.status !== 204) {
    try {
      responseJSON = await response.json();
    } catch (error) {
      return {
        status: response.status,
        error,
      };
    }
  }

  return {
    status: response.status,
    response: responseJSON,
  };
};

export default callBackend;
