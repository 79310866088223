import React, { Fragment, useState } from 'react';
import cx from 'classnames';

import Button from 'components/Button';
import Input from 'components/Input';
import Title from 'components/Title';
import callBackend from 'common/functions/callBackend';
import useFacebookLogin from './useFacebookLogin';

import styles from './styles.module.css';

const API_URL = process.env.REACT_APP_API_URL;

// TODO: Combine RemoveAccountView and Login views somehow
const RemoveAccountView = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const facebookLogin = useFacebookLogin();

  const deleteAccount = async ({ facebookAccessToken, usernameOrEmail, password }) => {
    setLoading(true);

    const formData = new FormData();
    usernameOrEmail && formData.append('usernameOrEmail', usernameOrEmail);
    password && formData.append('password', password);
    facebookAccessToken && formData.append('facebookAccessToken', facebookAccessToken);

    const loginRequest = await callBackend({
      method: 'POST',
      headers: null,
      url: `${API_URL}/auth/${facebookAccessToken ? 'facebook' : 'login'}`,
      body: formData,
    });

    if (!loginRequest.response.auth_token || !loginRequest.response.user) {
      setErrorMessage('Invalid login credentials.');
      setLoading(false);
      return;
    }

    const authToken = loginRequest.response.auth_token;
    const userId = loginRequest.response.user.id;

    const deleteRequest = await callBackend({
      method: 'DELETE',
      url: `${API_URL}/users/${userId}`,
      headers: { Authorization: 'JWT ' + authToken },
    });

    if (deleteRequest.status !== 204 || deleteRequest.error) {
      setErrorMessage('Account deletion failed. :(');
      setLoading(false);
      return;
    }

    setSuccess(true);
    setLoading(false);
  };

  return success ? (
    <div className={styles.container}>
      <Title>Account deleted</Title>

      <p className={styles.message}>You will be missed. :(</p>
    </div>
  ) : (
    <div className={styles.container}>
      <Title>Delete account</Title>

      <p className={styles.message}>
        Hope you don’t need to leave, but if you do, we want you to understand that all your pieces
        will be removed along with the account, and this cannot be undone. Sad to see you and your
        beautiful pieces go...
      </p>

      {errorMessage && (
        <p className={cx(styles.message, errorMessage && styles.errorMessage)}>{errorMessage}</p>
      )}

      {!facebookLogin.accessToken ? (
        <Fragment>
          <p className={cx(styles.message, styles.subtitle)}>Login with password</p>

          <Input
            className={styles.input}
            value={usernameOrEmail}
            onChange={(e) => setUsernameOrEmail(e.target.value)}
            placeholder="Username/Email"
          />

          <Input
            className={styles.input}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />

          <Button
            className={styles.button}
            warning={true}
            disabled={loading}
            onClick={!loading ? () => deleteAccount({ usernameOrEmail, password }) : undefined}
          >
            {loading ? 'Processing...' : 'Delete account'}
          </Button>

          <p className={cx(styles.message, styles.subtitle)}>Login with Facebook</p>

          <Button
            className={cx(styles.button, styles.facebookButton)}
            onClick={() => {
              facebookLogin.login();
            }}
          >
            Continue with Facebook
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <p className={cx(styles.message, styles.subtitle)}>Logged in with Facebook</p>

          <Button
            className={styles.button}
            warning={true}
            disabled={loading}
            onClick={
              !loading
                ? () =>
                    deleteAccount({
                      facebookAccessToken: facebookLogin.accessToken,
                    })
                : undefined
            }
          >
            {loading ? 'Processing...' : 'Delete account'}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

export default RemoveAccountView;
