import React, { useEffect, useState } from 'react';
import { useColorPaletteCanvas } from './useColorPaletteCanvas';
import { useColorPaletteInteraction } from './useColorPaletteInteraction';
import { RGB } from 'core';

const MAX_SELECTED_RGBS = 8;

const ColorPaletteContext = React.createContext();

const ColorPaletteProvider = props => {
  const [currentRGB, setCurrentRGB] = useState();
  const [selectedRGBs, setSelectedRGBs] = useState();
  const { visible, setVisible, getRGB, setCanvas } = useColorPaletteCanvas();
  const {
    currentPoint,
    selectedPoints,
    setCanvas: setCanvasInteraction,
  } = useColorPaletteInteraction({ maxSelectedPoints: MAX_SELECTED_RGBS });

  useEffect(() => {
    setCurrentRGB(currentPoint ? getRGB(currentPoint) : null);
  }, [currentPoint]);

  useEffect(() => {
    setSelectedRGBs(
      selectedPoints ? selectedPoints.map(selectedPoint => getRGB(selectedPoint)) : null
    );
  }, [selectedPoints]);

  const selectRandomRGBs = () => {
    const colorCount = Math.floor(Math.random() * (MAX_SELECTED_RGBS - 1)) + 2;
    setSelectedRGBs(Array.from({ length: colorCount }, () => RGB.random()));
  };

  return (
    <ColorPaletteContext.Provider
      value={{
        currentRGB,
        currentPoint,
        selectedRGBs,
        setCanvas: canvas => {
          setCanvas(canvas);
          setCanvasInteraction(canvas);
        },
        visible,
        setVisible,
        selectRandomRGBs,
      }}
    >
      {props.children}
    </ColorPaletteContext.Provider>
  );
};

export { ColorPaletteContext, ColorPaletteProvider };
