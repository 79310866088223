import { useEffect, useRef, useState, useContext } from 'react';
import { SettingsContext } from 'context/settings';

import tock from 'static/sounds/tock.mp3';
import _ from 'lodash';

const tockSound = new Audio(tock);

const useColorPaletteInteraction = ({ maxSelectedPoints }) => {
  const [canvas, setCanvas] = useState();
  const [currentPoint, setCurrentPoint] = useState();
  const [longPressPoint, setLongPressPoint] = useState();
  const [selectedPoints, setSelectedPoints] = useState();
  const { sound } = useContext(SettingsContext);

  const ref = useRef();
  ref.current = { currentPoint, longPressPoint, selectedPoints };

  useEffect(() => {
    if (!canvas) return;

    const onMouseDown = event => {
      event.stopPropagation();

      setSelectedPoints([]);

      canvas.addEventListener('mousemove', onMouseMove);
      canvas.addEventListener('mouseup', onMouseUp);

      const point = { x: event.clientX, y: event.clientY };

      setCurrentPoint(point);
      setLongPressPoint(point);

      onLongPress();
    };

    const onMouseMove = event => {
      const point = { x: event.clientX, y: event.clientY };
      const longPressPoint = ref.current.longPressPoint;

      setCurrentPoint(point);

      const distance = Math.hypot(point.x - longPressPoint.x, point.y - longPressPoint.y);

      if (distance > 3) {
        setLongPressPoint(point);
        onLongPress();
      }
    };

    const onMouseUp = () => {
      const { currentPoint, selectedPoints } = ref.current;

      canvas.removeEventListener('mousemove', onMouseMove);
      canvas.removeEventListener('mouseup', onMouseUp);

      if (selectedPoints.length === 0) {
        if (sound) {
          tockSound.play();
        }
        setSelectedPoints([...selectedPoints, currentPoint]);
      }

      setCurrentPoint(undefined);

      onLongPress.cancel();
    };

    const onLongPress = _.debounce(() => {
      const { currentPoint, selectedPoints } = ref.current;

      const points = [...selectedPoints, currentPoint];
      if (sound) {
        tockSound.play();
      }

      while (points.length > maxSelectedPoints) {
        points.shift();
      }

      setSelectedPoints(points);
    }, 750);

    canvas.addEventListener('mousedown', onMouseDown);

    return () => {
      canvas.removeEventListener('mousedown', onMouseDown);
      canvas.removeEventListener('mousemove', onMouseMove);
      canvas.removeEventListener('mouseup', onMouseUp);
    };
  }, [sound, canvas]);

  return { currentPoint, selectedPoints, setCanvas };
};

export { useColorPaletteInteraction };
