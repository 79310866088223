import { useState } from 'react';
import RGB from 'core/RGB';

const GRADIENT_LENGTH = 10000;
const MAX_RGBS = 8;
// const NAME = 'colorPalette';

let counter = 0;

const randomRGBs = (() =>
  [...new Array(Math.ceil(Math.random() * MAX_RGBS))].map(
    () => new RGB(Math.random() * 0xffffff)
  ))();

const getGradientColor = (rgbs, percent) => {
  let fraction = 1 / (rgbs.length - 1);
  let position = percent / fraction;
  let positionFloored = Math.floor(position);
  if (position - positionFloored < 0.0001) {
    return rgbs[positionFloored];
  }
  let i = positionFloored;
  let rgb1 = rgbs[i];
  let rgb2 = rgbs[i + 1];
  let rgbOut = new RGB();
  percent = position - i;
  try {
    rgbOut.r = Math.floor(Math.abs((rgb2.r - rgb1.r) * percent + rgb1.r));
    rgbOut.g = Math.floor(Math.abs((rgb2.g - rgb1.g) * percent + rgb1.g));
    rgbOut.b = Math.floor(Math.abs((rgb2.b - rgb1.b) * percent + rgb1.b));
  } catch (error) {
    rgbOut.r = 0;
    rgbOut.g = 0;
    rgbOut.b = 0;
  }
  return rgbOut;
};

const useColorPaletteTool = () => {
  const [rgbs, setRGBs] = useState(randomRGBs);

  const getRGB = () => {
    if (rgbs.length === 1) return rgbs[0];

    ++counter;
    let gradientLength = GRADIENT_LENGTH * (rgbs.length - 1);
    let gradientDirection = Math.floor(counter / gradientLength) % 2 === 0 ? 1 : -1;
    let gradientPercent = (counter % gradientLength) / gradientLength;
    gradientPercent = gradientDirection > 0 ? gradientPercent : 1 - gradientPercent;
    return getGradientColor(rgbs, gradientPercent);
  };

  return { rgbs, setRGBs, getRGB };
};

export default useColorPaletteTool;
