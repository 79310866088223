const API_URL = process.env.REACT_APP_API_URL;

export const getPieces = async (queryParamsString) => {
  try {
    const res = await fetch(`${API_URL}/pieces?${queryParamsString}`);
    return await res.json();
  } catch (err) {
    throw new Error(err);
  }
};
