import React from 'react';
import Title from 'components/Title';
import styles from './styles.module.css';

const NotFoundView = () => {
  return (
    <div className={styles.container}>
      <Title>
        Oops, something went wrong.
        <br />
        The page you are looking for doesn't exist.
      </Title>
    </div>
  );
};

export default NotFoundView;
