const API_URL = process.env.REACT_APP_API_URL;

export const postPieceReport = async ({ id, description }) => {
  try {
    return await fetch(`${API_URL}/pieces/${id}/report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        description,
      }),
    });
  } catch (err) {
    throw new Error(err);
  }
};
