import { Howl } from 'howler';
import { useState, useEffect } from 'react';

const sound = new Howl({
  src: [require('static/sounds/noise.mp3')],
  volume: 0,
});

const useSandboxInteraction = ({ autoFlow, autoFlowMode, setAutoFlowMode, muted, activated }) => {
  const [canvas, setCanvas] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(null);

  useEffect(() => {
    if (!canvas) return () => {};

    const onStart = (event) => {
      const isTouch = event.type.indexOf('touch') === 0;

      isTouch && event.preventDefault();

      if (!muted) {
        sound.play();
        sound.fade(0, 0.01, 500);
      }

      setAutoFlowMode(false);
      onMove(event);

      canvas.addEventListener(isTouch ? 'touchend' : 'mouseup', onEnd);
      canvas.addEventListener(isTouch ? 'touchmove' : 'mousemove', onMove);
    };

    const onMove = (event) => {
      const isTouch = event.type.indexOf('touch') === 0;

      isTouch && event.preventDefault();

      if (!enabled) return null;

      const { clientX, clientY } = isTouch ? event.changedTouches[0] : event;

      setCurrentPoint({
        x: clientX / (window.innerWidth / canvas.width),
        y: clientY / (window.innerHeight / canvas.height),
      });
    };

    const onEnd = (event) => {
      const isTouch = event.type.indexOf('touch') === 0;

      isTouch && event.preventDefault();

      if (!muted) {
        sound.fade(0.01, 0, 500);
      }

      setCurrentPoint(null);
      canvas.removeEventListener(isTouch ? 'touchmove' : 'mousemove', onMove);
    };

    const removeEventListeners = () => {
      canvas.removeEventListener('mousedown', onStart);
      canvas.removeEventListener('touchstart', onStart);
      canvas.removeEventListener('mousemove', onMove);
      canvas.removeEventListener('touchmove', onMove);
      canvas.removeEventListener('mouseup', onEnd);
      canvas.removeEventListener('touchend', onEnd);
    };

    if (autoFlow && autoFlowMode) {
      canvas.addEventListener('mousemove', onMove);
      canvas.addEventListener('touchmove', onMove);
      canvas.addEventListener('mousedown', onEnd);
      canvas.addEventListener('touchstart', onEnd);
    }

    if (activated) {
      canvas.addEventListener('mousedown', onStart);
      canvas.addEventListener('touchstart', onStart);

      return removeEventListeners;
    } else {
      removeEventListeners();
    }
  }, [canvas, enabled, autoFlow, autoFlowMode, setAutoFlowMode, muted, activated]);

  return {
    canvas,
    setCanvas,
    setEnabled,
    currentPoint,
  };
};

export { useSandboxInteraction };
