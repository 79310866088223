import React, { useState } from 'react';

// Hooks
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';

// Components
import Gallery from 'components/Gallery';

const RecentView = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  useInfiniteScroll({
    loading,
    space: 600,
    callback: () => {
      setPage(page + 1);
      setLoading(true);
    }
  });

  return (
    <Gallery
      title='Recent'
      type='recent'
      pageNumber={page}
      loading={loading}
      fetchedCallback={() => setLoading(false)}
    />
  );
};

export default RecentView;
