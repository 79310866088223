import React from 'react';
import styles from './styles.module.css';
import cx from 'classnames';

// Use Font Book on Mac to read unicodes of the special icon characters
const icons = {
  close: `\u271d`,
  ellipsis: `\u2026`,
  share: `\ue715`,
  circleEmpty: `\u229f`,
  circleFull: `\u229e`,
};

const Icon = ({ name, className, ...props }) => {
  return (
    <span className={cx(styles.icon, className)} {...props}>
      {icons[name]}
    </span>
  );
};

export default Icon;
