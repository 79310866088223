import * as md5 from 'md5';
import md5File from 'common/functions/md5File';
import callBackend from 'common/functions/callBackend';

const API_URL = process.env.REACT_APP_API_URL;
const APP_VERSION = process.env.REACT_APP_VERSION;

const postPiece = async ({ file, username, caption }) => {
  const hash = md5('ranskanperunat' + caption);
  const fileHash = await md5File(file);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('hash', hash);
  formData.append('fileHash', fileHash);
  formData.append('username', username);
  formData.append('caption', caption);
  formData.append('mode', 'web');
  formData.append('hd', '0');
  formData.append('appVersion', APP_VERSION);
  formData.append('tools[]', 'colorPalette');

  const { status, response, error } = await callBackend({
    method: 'POST',
    headers: null,
    url: `${API_URL}/pieces`,
    body: formData,
  });

  const callWasSuccessful = status === 201;

  return { callWasSuccessful, response, error };
};

export default postPiece;
