import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

import Span from 'components/Span';

import styles from './styles.module.css';

const Logo = ({ big, light, ...props }) => {
  const classNames = cx(styles.logo, { [styles.big]: big, [styles.light]: light });
  return (
    <Span className={classNames} {...props}>
      thisissand
    </Span>
  );
};

Logo.propTypes = {
  big: PropTypes.bool,
  light: PropTypes.bool,
};

Logo.defaultProps = {
  big: false,
  light: false,
};

export default Logo;
