import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-shortformat';

import A from 'components/A';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Image from 'components/Image';
import Input from 'components/Input';
import camera from 'static/sounds/camera.mp3';
import { useBreakpoint } from 'hooks/useBreakpoint';

import styles from './styles.module.css';

const cameraSound = new Audio(camera);
cameraSound.volume = 0.1;

const SaveModal = ({ cancel, submit, preview, pieceId, saving, hasSound }) => {
  const [name, setName] = useState('');
  const [caption, setCaption] = useState('');
  const [isError, setError] = useState(null);
  const username = name.replace(/\s/g, '');

  const bpXs = useBreakpoint('xs');

  return (
    <Flex
      width={bpXs ? '100%' : '480px'}
      height={bpXs ? '100%' : undefined}
      maxHeight="100%"
      column
      bg="white"
      borderRadius={bpXs ? '0' : '1em'}
      className={styles.modal}
      onClick={(e) => e.stopPropagation()}
    >
      <Flex fullWidth alignItems="flex-start">
        <Image src={preview} maxWidth="100%" style={{ objectFit: 'contain' }} alt="Preview" />
      </Flex>

      <Flex column p="1em">
        <Input
          my="1em"
          ghost={true}
          value={name}
          onChange={(e) => {
            if (e.target.value.length >= 254) {
              return;
            }

            setName(e.target.value);
            if (name.replace(/\s/g, '')) {
              setError(false);
            }
          }}
          placeholder="Enter your nickname"
          error={isError}
        />
        <Input
          my="1em"
          ghost={true}
          value={caption}
          onChange={(e) => {
            if (e.target.value.length >= 254) {
              return;
            }

            setCaption(e.target.value);
          }}
          placeholder="Write a caption"
        />

        <Flex justifyContent="flex-end">
          {!saving &&
            (!pieceId ? (
              <Fragment>
                <Button ghost={true} onClick={cancel}>
                  Cancel
                </Button>

                <A ml="1em" href={preview} download={`thisissand-${moment()}`}>
                  <Button ghost={true} onClick={cancel}>
                    Download
                  </Button>
                </A>
              </Fragment>
            ) : (
              <Link to={`piece/${pieceId}`}>
                <Button ml="1em" ghost={true}>
                  Shared in Thisissand
                </Button>
              </Link>
            ))}

          {!pieceId ? (
            <Button
              ml="1em"
              disabled={saving}
              onClick={
                !saving
                  ? () => {
                      if (!username) {
                        setError(true);
                        return;
                      }
                      submit(username, caption);
                    }
                  : undefined
              }
            >
              {saving ? 'Sending...' : 'Share in Thisissand'}
            </Button>
          ) : (
            <Button ml="1em" onClick={cancel}>
              Done
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

SaveModal.propTypes = {
  hasSound: PropTypes.bool,
  saving: PropTypes.bool,
  cancel: PropTypes.func,
  pieceId: PropTypes.number,
  submit: PropTypes.func,
};

SaveModal.defaultProps = {
  pieceId: 0,
  saving: false,
  hasSound: false,
  cancel: () => {},
  submit: () => {},
};

export default SaveModal;
