export default class Particle {
  static EMPTY = 0;
  static STATIC = 1;
  static IN_AIR = 2;
  static STACKING = 3;
  static pool = [];

  _x = 0;
  _y = 0;
  xRound = 0;
  yRound = 0;
  vx = 0;
  vy = 0;
  colour = 0x808080;
  status = this.IN_AIR;

  static getParticle() {
    if (this.pool.length === 0) {
      let i = 10000;
      while (i--) this.pool.push(new Particle());
    }

    return this.pool.pop();
  }

  static returnParticle(p) {
    return this.pool.push(p);
  }

  get x() {
    return this._x;
  }

  set x(value) {
    if (this._x === value) return;
    this._x = value;
    this.xRound = this.x | 0; //Math.round(value);  //
  }

  get y() {
    return this._y;
  }

  set y(value) {
    if (this._y === value) return;
    this._y = value;
    this.yRound = this.y | 0; //Math.round(value); //
  }
}
