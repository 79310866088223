export default class IterableArray extends Array {
  _currentIndex = 0;

  get currentIndex() {
    return this._currentIndex;
  }

  set currentIndex(value) {
    this._currentIndex = value;
  }

  next = () => {
    if (this.length === 0) return null;
    if (this._currentIndex > this.length - 1) this._currentIndex = 0;
    return this[this._currentIndex++];
  };
}
