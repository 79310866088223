import styled from 'styled-components';
import { flexbox } from 'styled-system';

import Box from '../Box';

const Flex = styled(Box)`
  ${(props) => (props.center || props.alignCenter) && 'align-items: center'};
  ${(props) => (props.center || props.justifyCenter) && 'justify-content: center'};
  ${(props) => props.column && 'flex-direction: column'};
  ${() => 'display: flex'};
  ${flexbox}
`;

export default Flex;
