import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import ColorButton from '../ColorButton/';
import ColorPalette from '../ColorPalette/';
import DoneIcon from 'static/doneIcon.svg';
import DiscardIcon from 'static/discardIcon.svg';
import HamburgerIcon from 'static/hamburgerIcon.svg';
import IncompletePiece from '../Modal/components/IncompletePiece';
import postPiece from 'hooks/PostPiece';
// import useDeviceDetection from 'hooks/useDeviceDetection';
import useTool from 'hooks/Tool/useTool';
import { ColorPaletteContext } from 'hooks/ColorPalette/ColorPaletteContext';
import { DiscardModal, SaveModal } from 'components/Modal';
import { ModalContext } from 'context/modal';
import { SandboxContext } from 'context/sandbox';
import { useSandbox } from 'hooks/useSandbox';

import styles from './styles.module.css';

const Sandbox = ({ activated, onClick, children, sound, autoFlow }) => {
  const { rgbs, getRGB } = useTool();
  const { drawing, toggleDrawing } = useContext(SandboxContext);
  const [autoFlowMode, setAutoFlowMode] = useState(false);
  const [requestPiece, setRequestPiece] = useState(false);
  const { modal, setModalParams } = useContext(ModalContext);
  const {
    visible: colorPaletteVisible,
    setVisible: setColorPaletteVisible,
    currentRGB,
    currentPoint,
  } = useContext(ColorPaletteContext);
  const { blob, piece, setCanvas, resetCanvas, isReadyToSubmit } = useSandbox({
    sound,
    getRGB,
    activated,
    requestPiece,
    autoFlow,
    autoFlowMode,
    setAutoFlowMode,
  });
  const [pieceId, setPieceId] = useState(0);
  const [loading, setLoading] = useState(false);

  const { isMobile } = { isMobile: false }; //useDeviceDetection();

  const postUserPiece = async (username, caption) => {
    setLoading(true);
    try {
      const { callWasSuccessful, response, error } = await postPiece({
        file: blob,
        username,
        caption,
      });

      if (error) throw error;

      if (callWasSuccessful) {
        setLoading(false);
        setPieceId(response.id);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    setModalParams({
      ...modal,
      onDismiss: () => setRequestPiece(false),
      id: 'submit-piece',
      opened: requestPiece,
      component: (
        <SaveModal
          hasSound={sound}
          pieceId={pieceId}
          saving={loading}
          preview={piece}
          submit={postUserPiece}
          cancel={() => {
            setModalParams({ opened: false });
            setRequestPiece(false);
          }}
        />
      ),
    });
  }, [loading, pieceId, blob, piece, requestPiece]);

  const toggleDiscardModal = () => {
    setModalParams({
      ...modal,
      id: 'discard-piece',
      opened: true,
      component: (
        <DiscardModal
          title="Discard Piece"
          onSuccess={() => {
            resetCanvas();
            setModalParams({ opened: false });
          }}
          onCancel={() => setModalParams({ opened: false })}
        >
          Lose this beautiful piece forever?
        </DiscardModal>
      ),
    });
  };

  const toggleIncompletePieceModal = () => {
    setModalParams({
      ...modal,
      id: 'incomplete-piece',
      opened: true,
      component: <IncompletePiece onCloseTrigger={() => setModalParams({ opened: false })} />,
    });
  };

  const toggleSaveModal = () => {
    if (isReadyToSubmit()) {
      setRequestPiece(true);
      return;
    }
    // Ask user to go on for a bit more
    toggleIncompletePieceModal();
  };

  const sandboxStyles = cx({
    [styles.sandbox]: true,
    [styles.sandbox_active]: activated,
  });

  const coverStyles = cx({
    [styles.cover]: true,
    [styles.cover_mobile]: isMobile,
  });

  return (
    <div className={sandboxStyles}>
      <div className={coverStyles} />

      <canvas
        ref={setCanvas}
        onClick={!activated && !isMobile ? onClick : null}
        onDoubleClick={() => {
          if (autoFlow && !autoFlowMode) {
            setAutoFlowMode(true);
          }
        }}
      />

      {children}

      <div className={styles.header}>
        <img onClick={toggleDrawing} src={HamburgerIcon} alt="menu" />
        <img onClick={toggleDiscardModal} src={DiscardIcon} alt="discard" />
        <img onClick={toggleSaveModal} src={DoneIcon} alt="done" />
      </div>

      <ColorButton
        rgbs={rgbs}
        pointerEvents="all"
        rgbKeys={true}
        style={{
          position: 'absolute',
          top: '4em',
          right: '4em',
        }}
        onMouseUp={() => setColorPaletteVisible(!colorPaletteVisible)}
      />

      {drawing && <ColorPalette />}

      {currentRGB && (
        <ColorButton
          style={{ position: 'fixed', top: '0' }}
          followPoint={currentPoint}
          rgbs={[currentRGB]}
        />
      )}
    </div>
  );
};

Sandbox.propTypes = {
  onClick: PropTypes.func,
  activated: PropTypes.bool,
  sound: PropTypes.bool.isRequired,
  autoFlow: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

Sandbox.defaultProps = {
  onClick: () => {},
  activated: false,
};

export default Sandbox;
