import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Banner from 'components/Banner';
import BotanistBanner from 'components/BotanistBanner';
import Button from 'components/Button';
import Flex from 'components/Flex';
import Gallery from 'components/Gallery';
import Hero from 'components/Hero';
import Logo from 'components/Logo';
import MarketBanner from 'components/MarketBanner';
import Sandbox from 'components/Sandbox';
import Setting from 'components/Setting';
import SocialMedia from 'components/SocialMedia';
import Title from 'components/Title';
// import useDeviceDetection from 'hooks/useDeviceDetection';
import { SandboxContext } from 'context/sandbox';
import { SettingsContext } from 'context/settings';

import styles from './styles.module.css';

const HomeView = () => {
  const { drawing, toggleDrawing } = useContext(SandboxContext);
  const { sound, toggleSound, autoFlow, toggleAutoFlow } = useContext(SettingsContext);

  const { isMobile } = { isMobile: false }; //useDeviceDetection();

  return (
    <>
      <Sandbox activated={drawing} autoFlow={autoFlow} sound={sound} onClick={toggleDrawing}>
        <Hero hidden={drawing}>
          <Logo big={true} light={true} />

          {/* {!isMobile && ( */}
          <Button
            fontSize="1.2em"
            className={styles.hero__shadow}
            style={{ pointerEvents: 'auto' }}
            onClick={toggleDrawing}
          >
            Play Now
          </Button>
          {/* )} */}

          <Flex mt="1em" width="100%" justifyContent="center">
            <MarketBanner
              className={styles.hero__shadow}
              type="ios"
              alt="Apple Store"
              url="https://itunes.apple.com/app/thisissand/id569414555"
            />

            <MarketBanner
              ml="1em"
              className={styles.hero__shadow}
              type="android"
              alt="Google Play"
              url="https://play.google.com/store/apps/details?id=air.com.beigeelephant.thisissand"
            />
          </Flex>
        </Hero>
      </Sandbox>

      <Gallery title="Recent" type="recent" items={5} />

      <Gallery title="Popular" type="popular" items={5} />

      <div className={styles.grid}>
        <div className={styles.content}>
          {!isMobile && (
            <div className={styles.settings}>
              <Title>Settings</Title>

              <Setting enabled={sound} title="Sound" onClick={toggleSound} />

              <Setting
                enabled={autoFlow}
                title="Auto-Flow"
                comment="Double tap on canvas to start auto-flow"
                onClick={toggleAutoFlow}
              />
            </div>
          )}

          <div className={styles.faq}>
            <Title>App Support</Title>

            <p>Read Frequently Asked Questions and get in touch.</p>

            <div className={styles.faq__buttons}>
              <Link to="faq">
                <Button>App Support</Button>
              </Link>
            </div>

            <Banner />
          </div>
        </div>

        <div className={styles.banner}>
          <BotanistBanner />
        </div>

        <div className={styles.social}>
          <SocialMedia />
        </div>
      </div>
    </>
  );
};

export default HomeView;
