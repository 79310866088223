import React, { useState } from 'react';

import DiscardModal from 'components/Modal/components/Discard';
import Textarea from 'components/Textarea';
import { postPieceReport } from 'helpers/api/post';

const ReportModal = ({ pieceId, onCloseTrigger }) => {
  const [description, setDescription] = useState('');
  const [hasError, setHasError] = useState(false);

  return (
    <DiscardModal
      title="Report Piece"
      successButtonTitle="Report"
      onCancel={onCloseTrigger}
      onSuccess={async () => {
        if (description.length < 3) {
          setHasError(true);
          return;
        }
        const reportResponse = await postPieceReport({
          id: pieceId,
          description,
        });

        if (reportResponse.status !== 204) {
          // TODO: Popup error?
        }

        onCloseTrigger();
      }}
    >
      <Textarea
        ghost={true}
        value={description}
        placeholder="Please describe how you find this piece inappropriate."
        error={hasError}
        onChange={({ target }) => {
          if (target.value.length >= 1000) return;
          if (target.value.length >= 3) setHasError(false);
          setDescription(target.value);
        }}
      />
    </DiscardModal>
  );
};

export default ReportModal;
