import React from 'react';
import styles from './styles.module.css';

// Icons
import Twitter from 'static/social/twitter.svg';
import Facebook from 'static/social/facebook.svg';
import Instagram from 'static/social/instagram.svg';

const socialLinks = [
  { title: 'Instagram', url: 'https://www.instagram.com/thisissandapp/', icon: Instagram },
  { title: 'Facebook', url: 'https://www.facebook.com/thisissand', icon: Facebook },
  { title: 'Twitter', url: 'https://twitter.com/thisissandapp', icon: Twitter },
];

const SocialMedia = () => {
  return (
    <div className={styles.icons}>
      {socialLinks.map((link) => (
        <a
          key={link.title}
          target="_blank"
          rel="noopener noreferrer"
          href={link.url}
          className={styles.icon}
        >
          <img src={link.icon} alt={link.title} />
        </a>
      ))}
    </div>
  );
};

export default SocialMedia;
