import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useState } from 'react';

import useEffectOnMountOnly from 'hooks/useEffectOnMountOnly';
import useLoginApi from 'context/UserContext/useLoginApi';
import useLogoutApi from 'context/UserContext/useLogoutApi';
import useUserApi from 'context/UserContext/useUserApi';

const AUTH_TOKEN_COOKIE_NAME = 'auth_token';

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(undefined);
  const [user, setUser] = useState(undefined);

  const loginApi = useLoginApi();
  const logoutApi = useLogoutApi();
  const userApi = useUserApi();

  useEffectOnMountOnly(() => {
    const authToken = Cookies.get(AUTH_TOKEN_COOKIE_NAME);

    if (authToken && authToken !== 'undefined') {
      setAuthToken(authToken);
      userApi.getUser({ authToken });
    }
  });

  useEffect(() => {
    if (loginApi.status !== 'complete' || !loginApi.response || !!loginApi.error) return;

    setAuthToken(loginApi.response.auth_token);
    setUser(loginApi.response.user);

    Cookies.set(AUTH_TOKEN_COOKIE_NAME, loginApi.response.auth_token, {
      domain: window.location.hostname,
      secure: window.location.protocol === 'https:',
      expires: 31,
    });
  }, [loginApi.status]);

  useEffect(() => {
    if (logoutApi.status !== 'complete') return;

    setAuthToken(undefined);
    setUser(undefined);

    Cookies.remove(AUTH_TOKEN_COOKIE_NAME, {
      domain: window.location.hostname,
    });
  }, [logoutApi.status]);

  useEffect(() => {
    if (userApi.status !== 'complete' || !userApi.response || !!userApi.error) return;

    setUser(userApi.response);
  }, [userApi.status]);

  return (
    <UserContext.Provider
      value={{
        authToken,
        login: loginApi.login,
        loginError: loginApi.error,
        loginStatus: loginApi.status,
        logout: logoutApi.logout,
        logoutStatus: logoutApi.status,
        user,
        userStatus: userApi.status,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
