import React, { Fragment, useEffect, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input';
import Spinner from 'components/Spinner';
import Title from 'components/Title';
import useFacebookLogin from 'views/RemoveAccountView/useFacebookLogin';
import { useUser } from 'context/UserContext';

import styles from 'views/RemoveAccountView/styles.module.css';

// TODO: Combine RemoveAccountView and Login views somehow
const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const { login, loginError, loginStatus, user, userStatus } = useUser();

  const facebookLogin = useFacebookLogin();

  const history = useHistory();

  const isLoggingIn = loginStatus === 'loading';
  const isGettingUser = userStatus === 'loading';

  // If/when user exists, go to root
  useEffect(() => {
    if (!user) return;
    history.replace({ pathname: '/' });
  }, [user]);

  return (
    <div className={styles.container}>
      {isGettingUser ? (
        <Spinner />
      ) : (
        <Fragment>
          <Title>Login</Title>

          {loginError && (
            <p className={cx(styles.message, loginError && styles.errorMessage)}>{loginError}</p>
          )}

          {!facebookLogin.accessToken ? (
            <Fragment>
              <p className={cx(styles.message, styles.subtitle)}>Login with password</p>

              <Input
                className={styles.input}
                value={usernameOrEmail}
                onChange={(e) => setUsernameOrEmail(e.target.value)}
                placeholder="Username/Email"
              />

              <Input
                className={styles.input}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />

              <Button
                className={styles.button}
                disabled={isLoggingIn}
                onClick={!isLoggingIn ? () => login({ usernameOrEmail, password }) : undefined}
              >
                {isLoggingIn ? 'Processing...' : 'Login'}
              </Button>

              <p className={cx(styles.message, styles.subtitle)}>Login with Facebook</p>

              <Button
                className={cx(styles.button, styles.facebookButton)}
                onClick={() => {
                  facebookLogin.login();
                }}
              >
                Continue with Facebook
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <p className={cx(styles.message, styles.subtitle)}>Logged in with Facebook</p>

              <Button
                className={styles.button}
                warning={true}
                disabled={isLoggingIn}
                onClick={
                  !isLoggingIn
                    ? () =>
                        login({
                          facebookAccessToken: facebookLogin.accessToken,
                        })
                    : undefined
                }
              >
                {isLoggingIn ? 'Processing...' : 'Login'}
              </Button>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Login;
