import Preview from 'components/Gallery/components/Preview';
import Spinner from 'components/Spinner';
import { ModalContext } from 'context/modal';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './styles.module.css';
import { formatPieceParams } from 'helpers';

const API_URL = process.env.REACT_APP_API_URL;

const renderModal = ({ context, history, piece }) => {
  context.setModalParams({
    id: 'piece-view',
    opened: true,
    onDismiss: () => history.push('/'),
    cover: false,
    component: (
      <Preview
        piece={piece}
        shadow={false}
        onDismiss={() => {
          history.push('/');
          context.setModalParams({ opened: false });
        }}
      />
    ),
  });
};

const PieceView = ({ match, history }) => {
  const context = useContext(ModalContext);
  const [isPieceLoaded, setIsPieceLoaded] = useState(false);
  const pieceId = match.params.id;

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${API_URL}/pieces/${pieceId}`);
        const piece = formatPieceParams(await res.json());
        setIsPieceLoaded(true);
        renderModal({ context, history, piece });
      } catch (err) {
        console.log(err);
      }
    })();
  }, [pieceId]);

  return <div className={styles.container}>{!isPieceLoaded && <Spinner centered={true} />}</div>;
};

export default withRouter(PieceView);
