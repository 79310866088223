import styled from 'styled-components';
import { color, layout, space, typography } from 'styled-system';

const A = styled.a`
  ${color}
  ${layout}
  ${space}
  ${typography}
`;

export default A;
