import React from 'react';

import ContentBox from 'components/ContentBox';
import Text from 'components/Text';
import Title from 'components/Title';

const FaqView = () => {
  return (
    <>
      <ContentBox maxWidth="768px">
        <Title>Frequently Asked Questions</Title>

        <Text title="Website vs. App" titleTagName="h3">
          Thisissand can be played in a laptop/desktop web browser (thisissand.com) OR with
          Thisissand app on iOS and Android devices.
          <br />
          <br />
          With the app you may create an account for yourself to hold on to your creations and like
          others' pieces. You may also purchase special tools, such as Photo Sand to create
          photographic sand pieces.
          <br />
          <br />
          Both website and app are ad-free and free to download.
          <br />
          <br />
          Get the app from{' '}
          <a href="https://itunes.apple.com/app/thisissand/id569414555">Apple App Store</a> and{' '}
          <a href="https://play.google.com/store/apps/details?id=air.com.beigeelephant.thisissand">
            Google Play Store
          </a>
          .
        </Text>

        <Text title="Selecting multicolor sand" titleTagName="h3">
          The color palette allows you to choose sand color which changes between selected colors
          over time. To try this out, press and hold on colors one after another WITHOUT releasing
          in between. You may also try out the random color button in the top left corner of the
          color palette.
        </Text>
      </ContentBox>

      <ContentBox maxWidth="768px">
        <Title>Contact</Title>

        <p>If FAQ didn't answer your question, you may also send us email.</p>

        <a href="mailto:support@thisissand.com">support@thisissand.com</a>
      </ContentBox>
    </>
  );
};

export default FaqView;
