import { useState, useEffect } from 'react';
import _ from 'lodash';

const useWindowSize = () => {
  const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [size, setSize] = useState(getWindowSize);

  useEffect(() => {
    const handleResize = _.throttle(() => setSize(getWindowSize()), 500);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
};

export { useWindowSize };
