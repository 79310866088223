import styled from 'styled-components';
import { color, layout, space, typography } from 'styled-system';

const Span = styled.span`
  ${color}
  ${layout}
  ${space}
  ${typography}
`;

export default Span;
