import React from 'react'
import ReactDOM from 'react-dom'
import { colors, themeBreakpoints } from 'theme'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import App from './App'
import ScrollToTop from 'hooks/scrollToTop'
import { ColorPaletteProvider } from 'hooks/ColorPalette/ColorPaletteContext'
import { ConfigProvider } from 'context/config'
import { ErrorBoundary } from 'context/ErrorBoundary'
import { GoogleAnalytics4Provider } from 'context/GoogleAnalytics4Context'
import { ModalContextProvider } from 'context/modal'
import { SandboxContextProvider } from 'context/sandbox'
import { SettingsContextProvider } from 'context/settings'
import { UserProvider } from 'context/UserContext'

// Global stylesheet to normalize browser default styles
import 'normalize.css'

// Global stylesheet
import './index.css'

ReactDOM.render(
  <ErrorBoundary>
    <ConfigProvider>
      <ThemeProvider theme={{ colors, breakpoints: themeBreakpoints }}>
        <UserProvider>
          <Router>
            <SettingsContextProvider>
              <GoogleAnalytics4Provider>
                <ColorPaletteProvider>
                  <ModalContextProvider>
                    <SandboxContextProvider>
                      <ScrollToTop>
                        <App />
                      </ScrollToTop>
                    </SandboxContextProvider>
                  </ModalContextProvider>
                </ColorPaletteProvider>
              </GoogleAnalytics4Provider>
            </SettingsContextProvider>
          </Router>
        </UserProvider>
      </ThemeProvider>
    </ConfigProvider>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
