import Flex from 'components/Flex';
import { useUser } from 'context/UserContext';
import usePieces from 'hooks/usePieces';
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import QuickAdminPiece from './QuickAdminPiece';

const QuickAdmin = () => {
  const [reportedPieces, setReportedPieces] = useState([]);

  const { authToken, user } = useUser();

  const { fetchPieces, pieces } = usePieces();

  const removePiece = (piece) => {
    setReportedPieces(reportedPieces.filter(({ id }) => id !== piece.id));
  };

  useEffect(() => {
    if (authToken && user && user.role === 'admin') {
      fetchPieces({ authToken });
    }
  }, [authToken, user]);

  useEffect(() => {
    setReportedPieces([...reportedPieces, ...pieces]);
  }, [pieces]);

  return (
    <Flex m="0 auto" maxWidth="480px" py={2} column alignCenter>
      {!user || user.role !== 'admin' ? (
        <Link to="/login">Login</Link>
      ) : (
        <>
          <h1>Auto-archived Pieces</h1>

          {reportedPieces.map((piece) => (
            <QuickAdminPiece
              key={piece.id}
              mt={3}
              piece={piece}
              onArchived={() => removePiece(piece)}
              onUnarchived={() => removePiece(piece)}
            />
          ))}
        </>
      )}
    </Flex>
  );
};

export default withRouter(QuickAdmin);
