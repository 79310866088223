import React from 'react';

// Components
import Title from 'components/Title';
import { IndentedParagraph as P } from 'components/IndentedParagraph'

import styles from './styles.module.css';
import textStyles from 'components/Text/styles.module.css';

let pKey = 0;
const rules = [
  {
    title: 'Audience',
    content: [
      <P key={pKey++}>Thisissand is intended for all audiences. Some functionalities may require registration to the service, which is for users over 13 years of age only. Users under 13 years of age are not allowed to register a user account.</P>
    ]
  },
  {
    title: 'Cost',
    content: [
      <P key={pKey++}>As long as you adhere to our Terms of Use you receive the right to use Thisissand for free. You may purchase some additional features and functionalities in the service. Don’t worry though - you won’t be able to buy anything accidentally; we will clearly tell you if you are about to purchase additional features and functionalities.</P>
    ]
  },
  {
    title: 'Safety',
    content: [
      <P key={pKey++}>Thisissand is committed to the safety of our users and will do its best efforts to ensure that the service is suitable and available for users of all ages.</P>
    ]
  },
  {
    title: 'Manners',
    content: [
      <P key={pKey++}>Please mind your manners. Do not bully or harass others. Keep the younger fellow users of Thisissand in mind and watch your language. If you infringe these Terms of Use or the law, we may revoke your right to use the service (ban you) and/or terminate your account.</P>
    ]
  },
  {
    title: 'Privacy',
    content: [
      <P key={pKey++}>Thisissand is a browser application (thisissand.com) and a native mobile application (downloadable from Apple App Store or Google Play Store). The browser and mobile applications have different terms regarding privacy and data collecting.</P>,
      <P key={pKey++}><strong>Browser application</strong></P>,
      <P key={pKey++}>The browser application collects general usage information which is used to analyse availability and traffic patterns of the service. No identifying information is collected expect what is provided by the users in the form of User Content. We do not give out identifying nor anonymised information of our users. We may use the collected information to monitor and develop the service.</P>,
      <P key={pKey++}><strong>Mobile application</strong></P>,
      <P key={pKey++}>In addition to general usage information the mobile application collects more detailed usage information such as which features of the service are used by which users. The mobile application can be used with a user account: identifying information is collected upon creation and modification of an account. We collect User Content that is provided by the users. We do not give out identifying nor anonymised information of our users. We may use the collected information to monitor and develop the service.</P>
    ]
  },
  {
    title: 'Storage',
    content: [
      <P key={pKey++}>The information you enter in the service is mainly stored and analysed on servers located in the United States and in the Netherlands.</P>
    ]
  },
  {
    title: 'Changes',
    content: [
      <P key={pKey++}>If we change our Terms of Use or Privacy Policy, we will notify you. If you do not accept the updated terms, please stop using the service.</P>
    ]
  },
  {
    title: 'Termination',
    content: [
      <P key={pKey++}>If you decide to terminate your registered Thisissand account, you may do so here: <a href="/account/remove">https://thisissand.com/account/remove</a></P>
    ]
  },
  {
    title: 'Feedback',
    content: [
      <P key={pKey++}>For any feedback for the service, please send us an e-mail to <a href="mailto:support@thisissand.com">support@thisissand.com</a></P>
    ]
  },
];

const RulesView = ({ children }) => {
  return (
    <>
      <div className={styles.container}>
        <Title>Welcome to Thisissand</Title>
        <p>This is a summary of the most important parts of our Terms of Use and Privacy Policy.  Please do note that the complete versions are the legally binding versions. Thisissand is provided by Beige Elephant Ltd, a Finnish company.</p>
        {rules.map((rule, index) => (
          <div key={index} className={textStyles.text}>
            <Title thin={true}>{rule.title}</Title>
            {rule.content.map(content => content)}
          </div>
        ))}
      </div>
      {children}
    </>
  );
};

export default RulesView;
