import React from 'react';
import PropTypes from 'prop-types';

import A from 'components/A';
import Image from 'components/Image';

const bannerCovers = {
  ios: require('../../static/bannerAppleStore.png'),
  android: require('../../static/bannerGooglePlay.png'),
};

const MarketBanner = ({ alt, url, type, small, ...props }) => {
  return (
    <A
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      maxWidth={small ? '150px' : '200px'}
      {...props}
    >
      <Image width="100%" src={bannerCovers[type]} alt={alt} />
    </A>
  );
};

MarketBanner.propTypes = {
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['ios', 'android']).isRequired,
  small: PropTypes.bool,
};

export default MarketBanner;
