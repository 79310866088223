import { useLayoutEffect } from 'react';

export default function useLockBodyScroll(activated) {
  useLayoutEffect(() => {
    if (activated) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.body.style.overflow = 'hidden';
      return;
    }

    window.onscroll = null;
    document.body.style.overflow = 'visible';
  }, [activated]);
};
