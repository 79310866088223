export const getGalleryQueryString = (
  galleryType = 'recent',
  // When no itemsPerPage - we use infinite scroll,
  // so we don't know how many pieces we are going to have
  itemsPerPage,
  pageNumber = 0,
  createdAtBegin
) => {
  const queryParams = new URLSearchParams()

  queryParams.set('page', pageNumber)

  if (itemsPerPage) {
    queryParams.set('itemsPerPage', itemsPerPage)
  }

  if (typeof createdAtBegin === 'string') {
    queryParams.set('filterRelativeTimeBegin[createdAt]', createdAtBegin)
  }

  if (galleryType.toLowerCase() === 'popular') {
    queryParams.set('orderBy', 'viewCount')
  }

  return queryParams.toString()
};
