import { useContext, useEffect } from 'react';
import { ColorPaletteContext } from 'hooks/ColorPalette/ColorPaletteContext';
import useColorPaletteTool from 'hooks/Tool/useColorPaletteTool';

const useTool = () => {
  const { rgbs, setRGBs, getRGB } = useColorPaletteTool();

  const colorPalette = useContext(ColorPaletteContext);

  useEffect(() => {
    if (!colorPalette.selectedRGBs) return;
    setRGBs(colorPalette.selectedRGBs);
  }, [colorPalette.selectedRGBs]);

  return { rgbs, getRGB };
};

export default useTool;
