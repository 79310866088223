import styled from 'styled-components';
import { border, color, layout, position, space } from 'styled-system';

const Box = styled.div`
  ${(props) => props.fullWidth && 'width: 100%'};
  ${(props) => props.fullHeight && 'height: 100%'};
  ${border}
  ${color}
  ${layout}
  ${position}
  ${space}
`;

export default Box;
