// import moment from 'moment-shortformat';
import React, { useState } from 'react';

import Box from 'components/Box';
import Thumbnail from 'components/Thumbnail';

const GalleryCard = ({ onClick, piece }) => {
  const [isBroken, setBroken] = useState(false);

  return (
    <Box display={isBroken ? 'none' : undefined}>
      {/* Let's hide the name from the thumbnail so the (inappropriate) names are not so visible */}
      {/* <Flex mb="0.5em" alignCenter justifyContent="space-between">
        <Username small>{piece.username}</Username>
        <Timestamp small>{moment(piece.createdAt).short(true)}</Timestamp>
      </Flex> */}

      <Thumbnail
        onClick={onClick}
        onError={() => {
          setBroken(true);
        }}
        piece={piece}
      />
    </Box>
  );
};

export default GalleryCard;
