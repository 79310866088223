import IterableArray from 'common/IterableArray/IterableArray';
import Particle from './Particle/Particle';
import Rndm from 'common/Rndm/Rndm';
import { Howl } from 'howler';

const ParticleEngine = (function () {
  const PARTICLES_PER_FRAME = 40;
  const GRAVITY = 0.8;
  const FRICTION = 0.999;
  const HORIZONTAL_VELOCITY_VARIANCE = 3;
  const VERTICAL_VELOCITY_VARIANCE = 3;

  let width;
  let height;

  let particleBoundsLeft;
  let particleBoundsRight;
  let particleBoundsTop;
  let particleBoundsBottom;

  let baseline;

  let particles;
  let particleStatuses;
  let particlesTotal;

  let rndmBrightnesses;
  let rndmHorizontalVelocities;
  let rndmVerticalVelocities;

  const noiseAudio = new Howl({
    src: [require('static/sounds/noise.mp3')],
    loop: true,
    volume: 0,
  });

  noiseAudio.play();

  function mute() {
    noiseAudio.muted = true;
  }

  function unmute() {
    noiseAudio.muted = false;
  }

  function initialize(params) {
    width = params.width;
    height = params.height;

    // We need space above the visible area to be able to pile a triangle on the edge of the image
    // Always use the shorter side to avoid bug when user rotates the device
    particleBoundsLeft = 0;
    particleBoundsRight = width - 1;
    particleBoundsTop = Math.round(Math.min(width, height) / -2);
    particleBoundsBottom = height - 1;

    baseline = Array.from(Array(width)).map(() => height);

    particles = [];

    particlesTotal = 0;

    particleStatuses = Array.from(Array(width * (height - particleBoundsTop))).map(
      () => Particle.EMPTY
    );

    // Create random value arrays to pull out values when rendering
    // This saves resources while rendering and also using the Rndm class we get exactly the same
    // random values everytime, which in turn enables us to recreate pieces.
    let i;
    let rndm;
    let rndmSeeds = new Rndm();

    rndm = new Rndm(rndmSeeds.random() * 0xffffff);
    rndmBrightnesses = new IterableArray();
    for (i = 0; i < 5000; i++) {
      rndmBrightnesses.push((rndm.random() - 0.5) * 20);
    }

    rndm = new Rndm(rndmSeeds.random() * 0xffffff);
    rndmHorizontalVelocities = new IterableArray();
    for (i = 0; i < 1000; i++) {
      rndmHorizontalVelocities.push((rndm.random() - 0.5) * HORIZONTAL_VELOCITY_VARIANCE);
    }

    rndm = new Rndm(rndmSeeds.random() * 0xffffff);
    rndmVerticalVelocities = new IterableArray();
    for (i = 0; i < 1000; i++) {
      rndmVerticalVelocities.push((rndm.random() - 0.5) * VERTICAL_VELOCITY_VARIANCE);
    }
  }

  function reset(props) {
    while (particles.length) {
      Particle.returnParticle(particles.pop());
    }

    initialize(props);
  }

  function addParticles({ x, y }, getRGB) {
    particlesTotal += PARTICLES_PER_FRAME;

    for (let i = 0; i < PARTICLES_PER_FRAME; i++) {
      let p = Particle.getParticle();
      p.status = Particle.IN_AIR;
      p.colour = tuneColor(getRGB(), rndmBrightnesses.next());
      p.x = x;
      p.y = y;
      p.vx = rndmHorizontalVelocities.next();
      p.vy = rndmVerticalVelocities.next();

      particles.push(p);
      setParticleStatusAt(p.x, p.y, p.status);
    }
  }

  function tuneColor(rgb, brightness) {
    if (brightness === 0) return rgb.color;
    let r = rgb.r;
    let g = rgb.g;
    let b = rgb.b;
    if (brightness < 0) {
      brightness = (100 + brightness) / 100;
      r *= brightness;
      g *= brightness;
      b *= brightness;
    } else {
      brightness /= 100;
      r += (0xff - r) * brightness;
      g += (0xff - g) * brightness;
      b += (0xff - b) * brightness;
      r = Math.min(r, 255);
      g = Math.min(g, 255);
      b = Math.min(b, 255);
    }
    return (r << 16) | (g << 8) | b;
  }

  function update(renderer) {
    if (!noiseAudio.muted) {
      const particlesStacking = particles.filter((p) => p.status === Particle.STACKING);
      const currentVolume = particlesStacking.length * 0.00025;

      if (particlesStacking.length <= 250) {
        noiseAudio.volume(currentVolume);
      }
    }

    for (let i = 0; i < particles.length; i++) {
      let newX, newY;
      let p = particles[i];

      if (p.status === Particle.IN_AIR) {
        // Clear the old slot in the location hash
        setParticleStatusAt(p.xRound, p.yRound, Particle.EMPTY);

        // Resolve new position
        newX = p.x + p.vx;
        newY = p.y + p.vy;

        // If new position is out of horizontal bounds, bring
        // it back on the map and invert the x-vector.
        if (newX < particleBoundsLeft) {
          newX *= -1;
          p.vx *= -1;
        } else if (newX > particleBoundsRight) {
          newX = particleBoundsRight - (newX - particleBoundsRight);
          p.vx *= -1;
        }

        // Store the new x-value (we must do this before getNonCollidingY to get the correct result)
        p.x = newX;

        // Find the new y-position so the particle doesn't collide with static/stacking particle
        newY = getNonCollidingY(p.xRound, newY);

        // // Check if right below the particle is a static particle or ground
        // pBelow =
        //   newY < particleBoundsBottom
        //     ? getParticleStatusAt(p.xRound, newY + 1)
        //     : Particle.STATIC;
        // if (pBelow == Particle.STATIC || pBelow == Particle.STACKING)
        //   numParticlesHittingGround++;

        // Set new values
        p.y = newY;
        p.vy += GRAVITY;
        p.vx *= FRICTION;
        p.status = getParticleStatus(p);
      } else if (p.status === Particle.STACKING) {
        // Particle is not in air but it isn't static yet. Find out
        // if it needs to be dropped to left or right side
        const dropPoint = getDropPoint(p);
        if (dropPoint) {
          // Empty the slot for this particle since it will be moved
          setParticleStatusAt(p.xRound, p.yRound, Particle.EMPTY);
          p.x += dropPoint.x;
          p.y = dropPoint.y;

          // Sanitize the x-coordinate
          if (p.x < particleBoundsLeft) p.x = particleBoundsLeft;
          if (p.x > particleBoundsRight) p.x = particleBoundsRight;
        }

        // Update particle status
        p.status = getParticleStatus(p);
      }

      // Store the particles new status
      setParticleStatusAt(p.xRound, p.yRound, p.status);

      if (p.status === Particle.STATIC) {
        // Set the static particle surface at this x position
        if (p.yRound < baseline[p.xRound]) {
          baseline[p.xRound] = p.yRound;
        }

        // Remove particle from active particles and return to the particle pool
        Particle.returnParticle(particles.splice(i, 1)[0]);
      }

      // No need to render the pixel if it is above the top edge
      if (p.yRound < 0) continue;

      // Render particle
      if (p.status === Particle.IN_AIR) {
        // console.log(-1 + p.xRound / dx, 1 - p.yRound / dy);
        renderer.addParticle(p.xRound, p.yRound, p.colour);
      } else if (p.status === Particle.STACKING) {
        renderer.addParticle(p.xRound, p.yRound, p.colour);
      } else {
        renderer.addStaticParticle(p.xRound, p.yRound, p.colour);
      }
    }
  }

  function getNonCollidingY(xRound, y) {
    let yRound = y | 0;

    // If point is above the top extension top, return to extension top
    if (yRound <= particleBoundsTop) {
      return particleBoundsTop;
    }

    // If point is below our sandbox vertically, return it to particlebounds
    if (yRound > particleBoundsBottom) {
      y = yRound = particleBoundsBottom;
    }

    // If point is below the current static particle surface,
    // bring it directly up on the surface to cut the travel time
    let baselineY = baseline[xRound];
    if (yRound >= baselineY) {
      y = yRound = Math.max(baselineY - 1, particleBoundsTop);
    }

    let collideeStatus;
    while (yRound > particleBoundsTop) {
      collideeStatus = getParticleStatusAt(xRound, yRound);
      if (collideeStatus === Particle.EMPTY || collideeStatus === Particle.IN_AIR) return y;
      y--;
      yRound--;
    }

    return y;
  }

  function getDropPoint(p) {
    if (p.y > particleBoundsBottom) return null;

    let txLeft = p.xRound - 1;
    let txRight = p.xRound + 1;
    let ty = p.yRound + 1;
    let foundLeft;
    let foundRight;

    // Check that we're well inside boundaries. Otherwise mark
    // drop points as already found so they will remain as ty
    if (txLeft < particleBoundsLeft) foundLeft = true;
    if (txRight > particleBoundsRight) foundRight = true;

    let pBelowLeft = !foundLeft ? getParticleStatusAt(txLeft, ty) : 0;
    let pBelowRight = !foundRight ? getParticleStatusAt(txRight, ty) : 0;
    if (!foundLeft) foundLeft = pBelowLeft !== 0 && pBelowLeft !== Particle.IN_AIR;
    if (!foundRight) foundRight = pBelowRight !== 0 && pBelowRight !== Particle.IN_AIR;

    // If STATIC or STACKING particles in both immediate bottom corners, no dropping is done
    if (foundLeft && foundRight) return null;

    // Initialize left and right indexes
    let tyLeft = ty;
    let tyRight = ty;

    // Loop until we hit the bottom (or find both drop points)
    while (ty <= particleBoundsBottom) {
      // If left drop point hasn't yet been found, increase left index and check
      // if a particle was found and was STATIC or STACKING (ie. not IN_AIR).
      if (!foundLeft) {
        tyLeft = ty;
        pBelowLeft = getParticleStatusAt(txLeft, ty);
        if (pBelowLeft !== 0 && pBelowLeft !== Particle.IN_AIR) foundLeft = true;
      }

      // If right drop point hasn't yet been found, increase left index and check
      // if a particle was found and was STATIC or STACKING (ie. not IN_AIR).
      if (!foundRight) {
        tyRight = ty;
        pBelowRight = getParticleStatusAt(txRight, ty);
        if (pBelowRight !== 0 && pBelowRight !== Particle.IN_AIR) foundRight = true;
      }

      // If both drop points were found, break out of the loop
      if (foundLeft && foundRight) break;

      // Append ty
      ty++;
    }

    // Search continued to the bottom, so a static drop point is found in there
    if (ty === particleBoundsBottom + 1) {
      if (!foundLeft) {
        foundLeft = true;
        tyLeft = particleBoundsBottom + 1;
      }
      if (!foundRight) {
        foundRight = true;
        tyRight = particleBoundsBottom + 1;
      }
    }

    // Both drop points were found, decide which one to use primarily according to steepness
    // and secondary according to particle velocity
    const dropPoint = {};
    if (foundLeft && foundRight) {
      if (tyLeft > tyRight || (tyLeft === tyRight && p.vx < 0)) {
        dropPoint.x = -1;
        dropPoint.y = tyLeft - 1;
      } else {
        dropPoint.x = 1;
        dropPoint.y = tyRight - 1;
      }
      return dropPoint;
    } else if (foundLeft) {
      dropPoint.x = -1;
      dropPoint.y = tyLeft - 1;
      return dropPoint;
    } else if (foundRight) {
      dropPoint.x = 1;
      dropPoint.y = tyRight - 1;
      return dropPoint;
    }

    return null;
  }

  function getParticleStatus(p) {
    // Particle is on the bottom of the bitmap
    if (p.yRound >= particleBoundsBottom) return Particle.STATIC;

    // Particle is within our bitmap
    // Compare particle to the particle below it
    let pBelow = getParticleStatusAt(p.xRound, p.yRound + 1);

    // Particle has nothing, or an "in air" -particle directly below
    if (pBelow === Particle.EMPTY || pBelow === Particle.IN_AIR) return Particle.IN_AIR;

    // Particle below is static or stacking
    // Compare to particles below left and below right
    let pBelowLeft;
    let pBelowRight;
    if (p.xRound > 0 && p.xRound < particleBoundsRight) {
      // Particle is somewhere in the middle of the bitmap
      pBelowLeft = getParticleStatusAt(p.xRound - 1, p.yRound + 1);
      pBelowRight = getParticleStatusAt(p.xRound + 1, p.yRound + 1);
      // Particle has static particles on bottom, bottom left and bottom right side
      if (
        pBelow === Particle.STATIC &&
        pBelowLeft === Particle.STATIC &&
        pBelowRight === Particle.STATIC
      )
        return Particle.STATIC;
    } else if (p.xRound <= 0) {
      // Particle is at the left edge of the bitmap
      pBelowRight = getParticleStatusAt(p.xRound + 1, p.yRound + 1);
      // Particle has static particles on bottom and bottom right side
      if (pBelow === Particle.STATIC && pBelowRight === Particle.STATIC) return Particle.STATIC;
    } else if (p.xRound >= particleBoundsRight) {
      // Particle is at the right edge of the bitmap
      pBelowLeft = getParticleStatusAt(p.xRound - 1, p.yRound + 1);
      // Particle has static particles on bottom and bottom left side
      if (pBelow === Particle.STATIC && pBelowLeft === Particle.STATIC) return Particle.STATIC;
    }
    // Particle has various kinds of particles below it,
    // label it "stacking" and take away it's vertical velocity
    p.vy = 0;

    return Particle.STACKING;
  }

  function getParticleStatusAt(x, y) {
    y -= particleBoundsTop;
    return particleStatuses[y * width + x];
  }

  function setParticleStatusAt(x, y, status) {
    if (status === Particle.EMPTY && getParticleStatusAt(x, y) === Particle.STATIC) return;

    y -= particleBoundsTop;
    particleStatuses[y * width + x] = status;
  }

  let module = {};

  module.initialize = initialize;

  module.reset = reset;

  module.addParticles = addParticles;

  module.update = update;

  module.mute = mute;
  module.unmute = unmute;

  module.getParticlesTotal = () => particlesTotal;

  return module;
})();

export default ParticleEngine;
