const breakpointValues = [576, 768, 1024];

export const breakpoints = [
  { alias: 'xs', value: '0px', min: undefined, max: breakpointValues[0] },
  {
    alias: 'sm',
    value: `${breakpointValues[0]}px`,
    min: breakpointValues[0] + 1,
    max: breakpointValues[1],
  },
  {
    alias: 'md',
    value: `${breakpointValues[1]}px`,
    min: breakpointValues[1] + 1,
    max: breakpointValues[2],
  },
  { alias: 'lg', value: `${breakpointValues[2]}px`, min: breakpointValues[2] + 1, max: undefined },
];

export const breakpointAliases = breakpoints.reduce((acc, bp) => {
  acc[bp.alias] = bp;
  return acc
}, {});

export const colors = {
  red: '#FF2F2F',
  darkred: '#ED2C2C',
  gray: '#979797',
  lightgray: '#E0E0E0',
  blue: '#007DFF',
  white: '#FFFFFF',
  green: '#49D060',
  black: '#303030',
  coolGray: '#f4f4f4',
  darkgreen: '#45C25A',
};

export const themeBreakpoints = breakpointValues.map((bp) => `${bp}px`);
