import React, { useState } from 'react';

export const ModalContext = React.createContext({
  modal: {
    opened: false,
    header: '',
    children: '',
    actions: null,
    onDismiss: null,
    component: null,
    cover: true,
    shadow: true,
  },
});

export const ModalContextProvider = ({ children }) => {
  const [modal, setModalParams] = useState({
    opened: false,
    header: '',
    children: '',
    actions: null,
    onDismiss: null,
    component: null,
    cover: true,
    shadow: true,
  });
  return (
    <ModalContext.Provider value={{ modal, setModalParams }}>{children}</ModalContext.Provider>
  );
};
